.footer {
    height: max-content;
    width: 100vw;
    background: black;
    padding-top: 2rem;
    padding-bottom: 1rem;
    color: white !important;
}

.px-2 {
    padding-left: 2.5rem !important;
    padding-right: 1rem !important;
}

.links {
    display: flex;
    justify-content: center;
}

.links ul {
    margin: 0 !important;
    padding: 0 !important;
}

.links>div {
    flex-basis: 20vw;
    text-align: left;
}

.links li {
    display: inline;
    align-self: flex-start;
}

.links a {
    text-decoration: none;
    font-weight: 400;
    color: white;
    font-size: 14px;
    display: flex;
    padding-bottom: 1rem;
}

.divider {
    height: 0.5px;
    background: white;
    margin: 1rem 0;
}

.col-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
}

.col-6 {
    flex: 0 0 auto;
    width: 50%;
}

.flex {
    display: flex;
}

.m-1 {
    margin: 0.25rem !important;
}

.footer-heading {
    margin: 0 !important;
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
    font-size: 1.4rem;
    font-weight: 550;
}

.text-decoration-none {
    text-decoration: none !important;
}

.text-white {
    --bs-text-opacity: 1;
    color: white !important
}

.footer-copyright {
    justify-content: flex-end !important;
    text-align-last: end;
}

.justify-content-center {
    justify-content: center !important;
}

.footer-row {
    width: 95vw;
    height: 100%;
    display: flex;
    justify-content: space-between;
    justify-self: end;
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: black;
}

.footer-social-logos {
    flex: 0 0 auto;
    justify-content: flex-end;
    width: 8.33333333%;
    display: flex;
    align-self: flex-end !important;
}

.footer-social-logo {
    display: flex;
    flex-direction: row;
    height: 30px !important;
    width: 30px !important;
    margin-left: 0.3rem !important;
}

.footer-small-text {
    font-weight: 300;
    font-size: 1rem;
    display: flex;
    justify-content: space-between;
    /* Aligns items to left and right */
    width: 95vw;
    /* Adjust width to fit content */
    padding: 1rem 0;
    color: white !important;
    align-self: center;
}

.footer-flow-logo {
    width: 5.6rem;
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
}

@media (max-width: 768px) {
    .px-2 {
        padding-left: 1rem !important;
        padding-right: 0.5rem !important;
    }

    .links {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .links>div {
        width: 95%;
    }

    .footer-row {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .footer-row>ul:nth-of-type(1) {
        grid-column: 1 / 2;
    }

    .footer-row>ul:nth-of-type(2) {
        grid-column: 1 / 2;
    }

    .links ul {
        list-style: none;
        padding-left: 50px;
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .links li {
        margin-bottom: 0.5rem;
    }

    .links a {
        text-decoration: none;
        font-weight: 400;
        color: white;
        font-size: 14px;
        display: block;
        padding-bottom: 0.3rem;
        padding-right: 1rem;
        padding-left: 1rem;
    }

    .footer-social-logos {
        display: none;
        justify-content: center;
        width: 100%;
        padding: 1rem 0;
    }

    .footer-social-logo {
        height: 30px !important;
        width: 30px !important;
        margin-left: 0 !important;
    }

    .footer-small-text {
        flex-direction: column;
        font-weight: 300;
        font-size: 1rem;
        display: flex;
        width: 100%;
        padding: 1rem 0;
        color: white;
        justify-content: center;
        align-items: center;
    }

    .footer-copyright {
        text-align: center;
        margin-top: 1rem;
    }

    .footer-flow-logo {
        width: 5.6rem;
        margin: 0.75rem 0;
    }

    .footer-heading {
        color: var(--primary);
    }
}


.mobile-social-links {
    display: none;
}

/* Show the mobile social links only on mobile screens */
@media (max-width: 768px) {
    .mobile-social-links {
        display: flex;
        justify-content: flex-end;
        margin-right: 1rem;
        margin-bottom: 1rem;
        /* Adjust as needed */
    }

    .links .mobile-social-links a {
        padding: 0;
        margin: 0;
    }

    .mobile-social-links div {
        margin: 0.75rem 0;
        /* Adjust spacing as needed */
    }

    .mobile-social-links div+div {
        margin-left: 1rem;
    }
}
