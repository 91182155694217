.text {
  line-height: 30px;
}

.header {
  text-align: justify;
  background-color: black;
  color: var(--background-white);
  padding: 0 2em;
}

.header img {
  width: 58vw;
  margin: 40px auto;
  display: block;
  /* box-shadow: 0px 4px 14.3px 2px rgba(0, 0, 0, 0.25); */
}

#lines-bottom {
  position: absolute;
  width: 30%;
  top: 60%;
  margin: 0;
  z-index: 1;
  pointer-events: none;
}

#lines-left {
  position: absolute;
  top: 60%;
  right: 0%;
  margin: 0;
  width: 30%;
  z-index: 1;
  pointer-events: none;
}

.orange-btn-container {
  margin: auto;
  width: 330px;
  height: 63px;
  background-color: var(--accent);
  border-radius: 30px;
  border: solid 1px black;
  z-index: 10;
  transition: all ease-in-out 0.5s;

  &:hover {
    border: solid 1px var(--accent);
    background-color: black;
  }
}

.orange-btn {
  display: grid;
  place-items: center;
  border-radius: 30px;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: black;
  font-weight: 700;
  transition: all ease-in-out 0.5s;
  z-index: 10;

  &:hover {
    color: var(--accent);
  }
}

#space-home {
  width: 100%;
  height: 200px;
  background-color: black;
}

.header h1 {
  display: block;
  color: var(--background-white);
}

.header #heading {
  padding-bottom: 150px;
  padding-top: 150px;
}

#about-description {
  margin-left: 3em;
  animation: fadeIn 5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

span,
.home-anchor {
  color: var(--primary);
}

.header h4 {
  display: block;
  color: var(--background-white);
  padding: 1em 0;
}

.section {
  display: flex;
  flex-direction: column;
  height: fit-content;
  margin: 0;
  padding: 3vh 2vw;
  /* padding: 3em 4em; */
}

.section .title {
  text-align: left;
  margin-bottom: 1vh;
  line-height: 1.5;
}

.section .title1 {
  text-align: left;
  margin: 3vh 0;
}

.section .content {
  display: flex;
  /* padding: 3em 4em; */
  flex-direction: row;
  align-items: flex-start;
  height: fit-content;
  text-align: left;
}

.content .right {
  height: fit-content;
  margin: 0;
  flex: 1;
}

.content .left {
  height: fit-content;
  margin: 1vh 2vw;
  align-self: center;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.content .top {
  height: fit-content;
  align-self: center;
  margin: 1vh 0;
  width: 90vw;
}

.content .bottom {
  height: fit-content;
  margin: 1vh 2vw;
  align-self: center;
  width: calc(100% - 4vw);
}

#about-section {
  /* padding-top: 1em; */
  padding-bottom: 4em;
  padding-left: 4em;
  padding-right: 4em;
}

#about-section .right {
  position: relative;
}

#about-section .right .flow-bg {
  height: 400px;
  background-image: url("../img/flow-bg1.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
}

.hide1 {
  opacity: 0;
  transform: translateY(2em);
  transition: all 1.5s;
}

.show1 {
  opacity: 1;
  transform: translateY(-2em);
}

.hide2 {
  opacity: 0;
  transform: translateY(1em);
  transition: all 1s;
  transition-timing-function: ease;
}

.show2 {
  opacity: 1;
  transform: translateY(-1em);
}

.delay1 {
  transition-delay: 0.5s;
}

.delay2 {
  transition-delay: 0.2s;
}

#flow-turbo-section {
  padding: 3em 7em;
  background-color: var(--background-white);
  color: black;
}

#flow-turbo-section>.title {
  margin-top: 1vh;
}

#flow-turbo-section>.content {
  margin-bottom: 2vh;
}

#flow-turbo-section .right {
  align-self: flex-start;
}

#key-value-section {
  padding: 3em 2em;
}

#key-value-section .content {
  flex-direction: column;
}

#key-value-section .left {
  text-align: center;
}

/* #key-value-section .title,
#key-value-section .title1 {
  text-align: center;
} */

.card .title1 {
  text-align: center;
}

#key-value-section .card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  justify-content: center;
  /*width: 90%;*/
  margin: 0 auto;
}

#key-value-section .card {
  /* min-height: unset;
  height: 560px; */
  /* height: 200px; */
  height: 500px;
  width: 21vw;
  background-color: var(--background-white);
  color: black;
  /* padding: 20px; */
  padding: 0.75em;
  padding-bottom: 2.5em;
  display: block;
  border-radius: 0;
}

#key-value-section .card .text {
  font-size: 18px;
}

#key-value-section .card-no {
  background-color: var(--primary);
  color: black;
  margin: 0 auto;

  position: relative;
  width: 40px;
  height: 0;
  padding-bottom: 40px;
  text-align: center;
  vertical-align: middle;
  line-height: 40px;
  margin-bottom: 1vh;
}

.section .primary-btn {
  width: 100% !important;
  margin: 20px 0 !important;
}

#target-section .content {
  flex-direction: column;
}

#target-section .left {
  text-align: center;
}

#target-section .title,
#target-section .title1,
#target-section .text {
  text-align: center;
}

#target-section .card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  justify-content: center;
}

#target-section .card {
  min-height: unset;
  height: fit-content;
  width: 20vw;
  padding: 20px;
  display: block;
}

#target-section .card-icon {
  background-color: var(--primary);
  margin: 0 auto;
  position: relative;
  width: 60px;
  height: 60px;
  text-align: center;
  vertical-align: middle;
  margin-bottom: 1vh;
  display: flex;
  align-items: center;
}

#target-section .card-icon img {
  width: 60%;
  height: 60%;
}

#prod-section .content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 2vh 0;
}

#prod-section h2 {
  margin: 2vh 10vw;
}

#prod-section .left {
  margin-top: 2vh;
  margin-left: 10vw;
}

#prod-section .right {
  margin-right: 7vw;
}

/* #prod-section .img1, */
/* #prod-section .img2, */
/* #prod-section .img3, */
/* #prod-section .img4, */
/* #prod-section .img5 {
  background-repeat: no-repeat;
  background-size: cover;
  height: calc(100% - 8vh);
  width: calc(100% - 4vw);
} */

#prod-section .img1 {
  background-image: url("../img/home-bg1.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  transform: scale(0.6);
  position: relative;
  bottom: 5em;
  right: 4em;
}

#image1 {
  width: 100%;
  height: 100%;
}

#prod-section .img2 {
  background-image: url("../img/home-bg2.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  transform: scale(0.7);
  position: relative;
  right: 4em;
  bottom: 2.7em;
}

#prod-section .img3 {
  background-image: url("../img/home-bg3.png");
  width: 100%;
  height: 100%;
  transform: scale(0.7);
  position: relative;
  bottom: 2em;
  right: 3em;
}

#prod-section .img4 {
  background-image: url("../img/home-bg4.png");
  width: 100%;
  height: 100%;
  position: relative;
  transform: scale(0.7);
  right: 4em;
}

#prod-section .img5 {
  background-image: url("../img/home-bg5.png");
  width: 100%;
  height: 100%;
  transform: scale(0.7);
  position: relative;
  right: 1em;
  top: 1em;
}

#prod-section .img2,
#prod-section .img5 {
  background-position: right;
}

#open-platform-section .top {
  text-align: center;
  width: 80%;
  margin: 0 auto;
}

#open-platform-section .title,
#open-platform-section .title1,
#open-platform-section .text {
  text-align: center;
}

#open-platform-section button {
  margin-top: 10px !important;
  width: 400px !important;
}

#laser-target-section .content {
  flex-direction: column;
}

#laser-target-section .card-container {
  display: grid;
  grid-template-rows: auto auto;
  width: fit-content;
  margin: 0 auto;
}

#laser-target-section .card-row {
  display: grid;
  grid-template-columns: auto auto auto;
  width: fit-content;
}

#laser-target-section .card {
  min-height: unset;
  height: fit-content;
  width: 22vw;
  padding: 20px;
  display: block;
}

#laser-target-section .title {
  margin-bottom: unset;
}

#laser-target-section .title1 {
  text-align: center;
}

#benefits-section .content {
  flex-direction: column;
}

#benefits-section .card-container {
  display: grid;
  grid-template-rows: auto auto;
  width: 70%;
  display: block;
  margin: 0 auto;
}

#benefits-section .card-row {
  display: grid;
  grid-template-columns: auto auto auto;
}

#benefits-section .card {
  min-height: unset;
  height: fit-content;
  width: 100%;
  display: block;
  background-color: var(--background-white);
  display: grid;
  grid-template-columns: 350px auto;
  grid-auto-flow: column;
  color: black;
  margin: 1vh 0;
  padding: 1vh 0;
}

#benefits-section .left {
  margin: unset;
  width: 100%;
}

#benefits-section .left img {
  width: calc(100% - 200px);
  margin: 0 auto;
  height: auto;
}

#benefits-section .right {
  display: block;
}

#benefits-section .title {
  margin-bottom: unset;
}

#benefits-section button {
  display: block;
  margin: 10px auto !important;
  width: 400px !important;
}

#contact-section {
  background-color: var(--primary);
  color: black;
  margin: 5vh 0 0 0;
}

#contact-section .title {
  text-align: center;
}

#contact-section .contact-container {
  display: flex;
  grid-template-columns: 50% 50%;
  align-content: flex-start;
  width: 60%;
  margin: 0 auto;
}

#contact-section .left,
#contact-section .right {
  padding: 0 20px;
}

#contact-section .MuiTextField-root {
  width: 100% !important;
  margin: 10px 0;
}

#contact-section button {
  width: 100% !important;
  margin: 5px 0;
  height: 41px;
  background-color: black;
  color: var(--background-white);
}

.home-page .card-icon {
  background-color: var(--primary);
  margin: 0 auto;
  position: relative;
  width: 60px;
  height: 60px;
  text-align: center;
  vertical-align: middle;
  margin-bottom: 1vh;
  display: flex;
  align-items: center;
  border-radius: 10px;
}

.home-page .card-icon img {
  width: auto;
  height: 60%;
}

@media all and (min-width: 1920px) {
  .max-content {
    margin: 0 15vw;
  }

  #prod-section .left {
    margin-top: 2vh;
    margin-left: 0;
  }

  #prod-section .content {
    margin: 0 15vw;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .hidden {
    transition: all 1s;
  }
}

.form-fields {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Adds spacing between the input fields */
  align-items: center; /* Centers the form in the middle */
  width: 100%; /* Adjust as necessary */
}

.form-fields-container {
  display: flex;
  flex-direction: column;
  gap: 20px;  /* Adds space between fields */
}

.account-button_submit {
  background-color: #000;
  color: #fff;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  text-align: center;
  font-size: 16px;
  width: fit-content;
  margin-top: 20px;
}

.responseMessage {
  color: green;
  font-size: 16px;
  margin-top: 20px;
}

@media only screen and (max-width: 768px) {
  .form-fields-container {
    width: 100%; /* Ensure form adjusts to the screen size */
  }
}

