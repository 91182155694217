.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--background-grey);
}

.account-container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  justify-content: center;
  align-items: center;
}

.account-button {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 30px;
  width: 600px;
  height: 50px;
  border-radius: 10px;
  cursor: pointer;
}

.account-button p {
  font-weight: bold;
  font-size: 1.2em;
}

.alternate-account-button {
  background-color: white;
  border-width: 1px;
}

.account-button_submit, .account-button_salesforce, .account-button_hubspot {
  border: none;
}

.account-button_salesforce, .account-button_hubspot {
  color: white;
}

.account-button_submit {
  background-color: var(--primary);
}

.account-button_salesforce {
  background-color: #529edf;
}

.account-button_hubspot {
  background-color: var(--secondary);
}