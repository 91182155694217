.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  color: white;
  margin: 0 auto;
  padding: calc(1.5vh + 1vw) 0;
  z-index: 99;
}

.nav-font {
  font-size: calc(14px + 0.15vw);
  font-weight: 600;
}

.nav-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 2vw;
}

.nav-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin: 0;
  transition: all 0.3s ease;
  flex-grow: 2;
}

.nav-links.active {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: calc(100% + 1px);
  left: 0;
  right: 0;
  background-color: var(--color-black);
  text-align: center;
  padding: 60px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  align-items: flex-start;
}

.nav-links.active .account-buttons .orange-button,
.nav-links.active .account-buttons .green-button {
  /* width: calc(500px + 5vw); */
  width: 400px;
  padding: 20px 40px;
  font-size: 30px;
  border-radius: 25px;
}

.navigation a {
  color: var(--color-white);
  text-decoration: none;
  height: fit-content;
  display: block;
  width: max-content;
  cursor: pointer;
}

.nav-item {
  position: relative;
  display: inline-block;
}

/* Navigation Icons */

.home-icon {
  display: block; 
  height: auto;
  max-height: 35px;
  width: auto;
}

.home-icon-mobile {
  display: none; 
}

.menu-icon {
  display: none;
  cursor: pointer;
  color: var(--color-white);
  padding: 0 20px;
  z-index: 2;
}

.no-scroll {
  overflow: hidden;
}

.menu-wrapper {
  display: none;
}

.nav-links.active .menu-icon {
  position: fixed;
  top: 20px; 
  right: 20px;
  z-index: 1000; 
}

/* Outer Links */

.outer_link > a {
  display: flex;
  padding: 15px;
  transition: color 0.3s;
  width: max-content;
  position: relative;
}

.outer_link:not(.logo-link) > a::after {
  content: "";
  display: block;
  width: 0;
  height: 4px;
  background-color: var(--primary);
  transition: width 0.3s ease;
  position: absolute;
  left: 0;
  bottom: -5px;
}

.outer_link:not(.logo-link) > a:hover::after {
  width: 100%;
}

/* Dropdown Menu */

.nav-item:hover .dropdown-content {
  display: block;
  position: absolute;
  background-color: black;
}

.dropdown-content {
  display: none;
  position: absolute;
  /* background-color: var(--color-white);
  color: var(--color-black); */
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-top: 5px;
  width: 200px;
}

.dropdown-content li {
  color: var(--color-black);
  margin: 0;
  text-align: center;
}

.dropdown-content a {
  display: block;
  width: 100%;
  color: var(--color-black);
  transition: all 0.3s;
}

.dropdown-content a:hover {
  color: var(--color-white);
  background-color: var(--color-black);
}

/* Account Buttons */

.account-buttons {
  display: flex;
  column-gap: 30px;
  align-items: center;
}

.orange-button,
.green-button {
  padding: 10px 0;
  justify-content: center;
  align-items: center;
  transition: all ease-in-out 0.3s;
  width: calc(160px + 2vw);
}

.orange-button {
  background-color: #ff5b00;
  color: var(--color-white);
}

.orange-button:hover {
  background-color: var(--color-black);
  color: #ff5b00;
  box-shadow: inset 0px 0px 0px 1px #ff5b00;
}

.green-button {
  background-color: var(--primary);
  color: var(--color-black);
}

.green-button:hover {
  background-color: var(--color-black);
  color: var(--primary);
  box-shadow: inset 0px 0px 0px 1px var(--primary);
}

/* Media Queries */

/* Desktop */
@media all and (min-width: 1440px) {
  .navigation {
    padding: calc(2vh + 0.5vw) 2vw;
  }

  .dropdown-content a {
    padding: 15px 0px;
  }

  .home-icon {
    display: block; 
    height: auto;
    max-height: 35px;
    width: auto;
  }

  .home-icon-mobile {
    display: none; 
  }

  .menu-icon {
    display: none; 
  }

  .menu-wrapper {
    display: none; 
  }
}

/* Mobile Devices */

@media (max-width: 1439px) {
  ..outer_link > a {
    font-size: 40px;
  }
  .nav-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  .menu-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    padding: 20px;
  }

  .mobile-nav-logo {
    padding: 15px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .menu-icon {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    z-index: 2;
  }

  .nav-links {
    display: none;
    position: absolute;
    top: calc(100% + 1px);
    left: 0;
    right: 0;
    flex-direction: column;
    align-items: center;
    padding: 0;
  }

  .nav-links.active {
    background-color: black;
    display: flex;
    top: 0;
    position: fixed;
  }

  .outer_link + .outer_link {
    margin-top: 1vh;
    font-size: 40px;
  }

  .account-buttons {
    flex-direction: column;
    row-gap: 40px;
    padding: 15px;
    margin-top: 100px;
  }

  .home-icon {
    display: none; 
  }

  .home-icon-mobile {
    display: block;
    height: auto;
    max-height: 35px;
    width: auto;
  }

  .dropdown-content {
    display: block;
    position: static;
    background: none;
    box-shadow: none;
  }

  .dropdown-content li {
    margin: 0;
    padding: 0;
  }

  .nav-item.outer_link a {
    display: none;
  }
}