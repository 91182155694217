/* Section-1 */
.use-cases {
  margin: 40px auto;
  width: 90vw;
}

.use-cases .section1 {
  width: 100%;
}

.section1_title {
  background-color: var(--primary);
  padding: 100px 0px;
}

.custom-h1 {
  font-size: 50px;
  font-weight: bold;
}

.section1_subsection {
  margin: 30px 0;
}

.section1_subtext {
  font-size: 25px;
  font-weight: bold;
}

@media (max-width: 768px) {
  .custom-h1 {
    font-size: 30px;
  }

  .use-cases .section1 {
    font-size: 20px;
    width: 100%;
  }

  .section1_subtext {
    font-size: 18px;
  }
}

/* Section-2 */
.section2 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5vw;
  margin: 30px;
}

.section2_texts {
  flex: 1;
}

.section2_sub_title_text {
  padding-bottom: 20px;
  margin: 0;
  font-size: 30px;
}

.section2_img-container {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media screen and (min-width: 1600px) {
  .section2_img-container {
    max-height: 20vw;
  }
}

@media (max-width: 1200px) {
  .section2 {
    flex-direction: column-reverse;
  }
}

/* Section-3 */
.section3 {
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  background-color: #f3f3f3;
  padding: 40px;
  display: block;
}

.section3-cards-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin: 40px;
  justify-content: center;
}

.section3-item {
  padding: 20px;
}

.section3-item-title {
  font-size: 2.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.section3-item-description {
  font-size: 1.3rem;
}

@media (max-width: 1000px) {
  .section3 {
    display: none;
  }

  .section3-mobile-usecase-section {
    display: block;
    padding: 0 2vw;
  }
}

@media (min-width: 1001px) {
  .section3 {
    display: block;
  }

  .section3-mobile-usecase-section {
    display: none;
  }
}

/* Section-3 Mobile */
.section3-mobile-usecase-item {
  margin: 20px 0;
  box-sizing: border-box;
  background-color: #f3f3f3;
  padding: 10px;
  border-radius: 30px;
  overflow: hidden;
  transition: height 0.3s ease-out;
}

.section3-mobile-usecase-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.section3-mobile-usecase-title {
  font-size: 1rem;
  font-weight: bold;
  width: 350px;
}

.section3-mobile-titleIcon {
  height: 50px;
  width: 50px;
}

.section3-mobile-down {
  height: 15px;
  cursor: pointer;
  margin-right: 30px;
}

.section3-mobile-usecase-content {
  padding: 10px 0;
  font-size: 1rem;
  line-height: 1.5;
  display: block;
  margin: 25px;
}

.section3-mobile-usecase-content.hidden {
  display: none;
}

@media (max-width: 600px) {
  .section3-mobile-usecase-title {
    width: 150px;
  }
}

/* Section-4 */
.section4 {
  padding: 40px 0;
}

.section4-subheader_text {
  padding-bottom: 20px;
  font-size: 35px;
  font-weight: bold;
}

.section4-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.section4-left-content {
  flex: 1;
  margin-right: 20px;
}

.section4-right-content {
  flex: 1;
  font-size: 1rem;
}

@media (max-width: 1250px) {
  .section4-content {
    flex-direction: column;
  }

  .section4-left-content {
    margin-right: 0;
    margin-bottom: 20px;
  }
}

/* Section-5 */
.section5 {
  margin: 4vw 0;
}

.section5-subheader_text {
  padding-bottom: 20px;
  font-size: 35px;
  font-weight: bold;
}

.section5-content {
  display: flex;
  justify-content: space-between;
  gap: 3vw;
}

.section5-subsection {
  align-items: center;
  display: flex;
  text-align: left;
  flex-direction: column;
}

.section5-number {
  width: 5vw;
  height: 5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
  margin-right: 4vw;
}

.section5-mobile-usecase-section {
  display: none;
}

@media (max-width: 1000px) {
  .section5-content {
    display: none;
  }

  /* Section-5 Mobile */
  .section5-mobile-usecase-section {
    display: block;
    padding: 0 2vw;
  }

  .section5-mobile-usecase-item {
    margin: 20px 0;
  }

  .section5-mobile-usecase-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 20px 0;
  }

  .section5-mobile-usecase-title {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .section5-mobile-usecase-button {
    all: unset;
    cursor: pointer;
  }

  .section5-mobile-usecase-content {
    margin: 20px 0;
    padding: 0 10px;
    font-size: 1.2rem;
  }

  .section5-mobile-divider_black {
    height: 0.5px;
    background: #000000;
    margin: 10px 0;
  }
}

/* Section-6 */
.section6 {
  margin-top: 6vw;
}

.section6-sub_title_text {
  margin: 2vw 0;
  font-size: 30px;
}

/* Section-7 */
.section7 {
  margin-bottom: 4vw;
}

.section7-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5vw;
}

.section7-text-content {
  flex: 1;
}

.section7-img-container {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (max-width: 1200px) {
  .section7-content {
    flex-direction: column-reverse;
  }
}

/* Section-8 */
.section8 {
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  background-color: #f3f3f3;
}

.section8-subsection {
  width: 90vw;
  margin: 40px auto;
  padding-top: 40px;
  padding-bottom: 60px;
}

.section8-subsection_title {
  width: 80vw;
  margin: 2vw 0;
}

@media (max-width: 767px) {
  .section8 {
    display: none;
  }
}

.section8-mobile {
  margin-top: 80px;
  margin-bottom: 80px;
}

/* Section-9 */
.section9 {
  margin: 4vw 0;
}

.section9-row {
  margin: 4vw 0;
  display: flex;
  justify-content: space-between;
  gap: 5vw;
}

.section9-sub_section {
  flex: 1;
}

.section9-subheader_title {
  font-size: 30px;
  font-weight: bold;
  color: black;
  background-color: var(--primary);
  padding: 0.4vw;
  display: inline-block;
}

.section9-subheader-desc {
  padding: 0.4vw;
}

.section9-subheader_PropositionsTitle {
  font-size: 30px;
  font-weight: bold;
  color: black;
  padding: 0.4vw;
  display: inline-block;
}

.section9-subheader_text {
  font-size: 30px;
  font-weight: bold;
  padding-bottom: 10px;
}

@media (max-width: 1000px) {
  .section9 {
    display: none;
  }

  .section9-mobile-usecase-section {
    display: block;
  }

  /* Section-9 part2 Mobile */
  .section9-p2-mobile-usecase-section {
    display: block;
    padding: 0 2vw;
    margin-top: 80px;
  }

  .section9-p2-mobile-usecase-item {
    margin: 20px 0;
  }

  .section9-p2-mobile-usecase-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 20px 0;
  }

  .section9-p2-mobile-usecase-title {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .section9-p2-mobile-usecase-button {
    all: unset;
    cursor: pointer;
  }

  .section9-p2-mobile-usecase-content {
    margin: 20px 0;
    padding: 0 10px;
    font-size: 1.2rem;
  }

  .section9-p2-mobile-divider_black {
    height: 0.5px;
    background: #000000;
    margin: 10px 0;
  }
}

@media (min-width: 1001px) {
  .section9 {
    display: block;
  }

  .section9-mobile-usecase-section {
    display: none;
  }

  .section9-p2-mobile-usecase-section {
    display: none;
  }
}

/* Section-9 Mobile */
.section9-mobile-usecase-item {
  margin: 20px 0;
  box-sizing: border-box;
  background-color: #f3f3f3;
  padding: 10px;
  border-radius: 30px;
  overflow: hidden;
  transition: height 0.3s ease-out;
}

.section9-mobile-usecase-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 25px;
  padding-left: 0px;
  padding-right: 0px;
}

.section9-mobile-usecase-title {
  font-size: 1.2rem;
  font-weight: bolder;
  width: 350px;
  margin-left: 20px;
}

.section9-mobile-titleIcon {
  height: 60px;
  width: 60px;
  background: var(--primary);
  border-radius: 50px;
  padding: 10px;
}

.section9-mobile-down {
  height: 10px;
  cursor: pointer;
  margin-right: 30px;
}

.section9-mobile-usecase-content {
  padding: 10px 0;
  font-size: 1.2rem;
  line-height: 1.5;
  display: block;
  margin: 25px;
}

.section9-mobile-usecase-content.hidden {
  display: none;
}

@media (max-width: 600px) {
  .section9-mobile-usecase-title {
    width: 275px;
  }
}

/* Section-10 */
.section10 {
  padding: 2vw;
}

.section10-subheader_text {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
}

.section10-subheader_text2 {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
}

.section10-content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 2vw;
}

.section10-sub_content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1.5vw;
}

.section10-leftContent {
  display: flex;
  align-items: center;
  margin: 30px 0;
}

.section10-number {
  width: 5vw;
  height: 5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
  margin-right: 2vw;
  padding: 30px;
}

.section10-text {
  display: flex;
  flex-direction: column;
}

.section10-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.section10-img-container img {
  max-width: 100%;
  height: auto;
}

img {
  max-width: 100%;
  height: auto;
}

@media (min-width: 1001px) {
  .section10 {
    display: block;
  }

  .section10-mobile-usecase-section {
    display: none;
  }
}

@media (max-width: 1000px) {
  .section10 {
    display: none;
  }

  .section10-mobile-usecase-section {
    display: block;
  }
}

/* Section-10 Mobile */
.section10-mobile-usecase-item {
  margin: 20px 0;
  box-sizing: border-box;
  background-color: #f3f3f3;
  padding: 10px;
  border-radius: 30px;
  overflow: hidden;
  transition: height 0.3s ease-out;
}

.section10-mobile-subheader_text {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 50px;
}

.section10-mobile-img-container {
  height: 80vw;
  width: 80vw;
}

.section10-mobile-usecase-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px;
}

.section10-mobile-usecase-title {
  font-size: 1.5rem;
  font-weight: bold;
  width: 350px;
}

.section10-mobile-number1 {
  width: 5vw;
  height: 5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff5b00;
  margin-right: 2vw;
  padding: 30px;
  border-radius: 20px;
}

.section10-mobile-number2 {
  width: 5vw;
  height: 5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
  margin-right: 2vw;
  padding: 30px;
  border-radius: 20px;
}

.section10-mobile-titleIcon {
  height: 100px;
  width: 100px;
}

.section10-mobile-down {
  height: 15px;
  cursor: pointer;
  margin-right: 30px;
}

.section10-mobile-usecase-content {
  padding: 10px 0;
  font-size: 1.2rem;
  line-height: 1.5;
  display: block;
  margin: 25px;
}

.section10-mobile-usecase-content.hidden {
  display: none;
}

@media (max-width: 600px) {
  .section10-mobile-usecase-title {
    width: 175px;
  }
}

/* Section-11 */
.section11 {
  margin: 4vw 0;
}

.section11-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.section11-subheader_text {
  font-size: 2rem;
  font-weight: bold;
  padding-bottom: 20px;
  padding-top: 30px;
}

.section11-sub_title_text {
  font-size: 30px;
}

.section11-sub_title_desc {
  font-size: 22px;
}

.section11-grid-item {
  padding: 15px;
}

.section11-sub_content {
  flex: 1;
}

.section11-title {
  display: flex;
  margin: 20px 0;
}

.section11-text {
  margin: 30px 0;
}

/* Section-11 Mobile */
@media (max-width: 1000px) {
  .section11-content {
    display: none;
  }

  .section11-mobile-usecase-section {
    margin: 50px 0;
    display: block;
  }

  .section11-mobile-usecase-section {
    display: block;
    padding: 0 2vw;
  }

  .section11-mobile-usecase-item {
    margin: 20px 0;
  }

  .section11-mobile-usecase-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 20px 0;
  }

  .section11-mobile-usecase-title {
    font-size: 1.2rem;
    font-weight: bold;
  }

  .section11-mobile-usecase-button {
    all: unset;
    cursor: pointer;
  }

  .section11-mobile-usecase-content {
    margin: 20px 0;
    padding: 0 10px;
    font-size: 1.2rem;
  }

  .section11-mobile-divider_black {
    height: 0.5px;
    background: #000000;
    margin: 10px 0;
  }
}

@media (min-width: 1001px) {
  .section11 {
    display: block;
  }

  .section11-mobile-usecase-section {
    display: none;
  }
}

/* Section-12 */
.section12 {
  margin: 4vw 0;
}

.section12-row {
  display: flex;
  justify-content: space-between;
  gap: 5vw;
}

.section12-sub_content {
  flex: 1;
}

.section12-sub_title_text {
  font-size: 28px;
}

.section12-sub_title_desc {
  font-size: 22px;
}

.section12-content {
  width: 100%;
}

.section12-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.section12-grid-item {
  padding: 15px;
  padding-left: 0px;
  min-height: 100px;
}

.section12-grid-item.empty {
  visibility: hidden;
}

.section12-text {
  margin-top: 20px;
}

.section12-subheader_text {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
}

.section12-mobile-content {
  display: none;
}

.section12-mobile-number2 {
  color: black;
}

@media (max-width: 768px) {
  .section12 {
    background-color: black;
    color: white;
    padding: 50px 30px 50px 30px;
  }

  .section12-content {
    display: none;
  }

  .section12-mobile-content {
    display: block;
  }

  .section12-mobile-usecase-item {
    margin: 20px 0;
    box-sizing: border-box;
    background-color: #f3f3f3;
    padding: 10px;
    border-radius: 30px;
    overflow: hidden;
    transition: height 0.3s ease-out;
  }

  .section12-mobile-subheader_text {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 50px;
  }

  .section12-mobile-usecase-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px;
    padding-left: 0px;
  }

  .section12-mobile-usecase-title {
    font-size: 1rem;
    font-weight: bold;
    width: 350px;
    color: black;
    margin-left: 20px;
  }

  .section12-text {
    color: white;
  }

  .section12-mobile-titleIcon {
    height: 50px;
    width: 50px;
  }

  .section12-mobile-down {
    height: 10px;
    cursor: pointer;
    margin-right: 10px;
  }

  .section12-mobile-usecase-content {
    padding: 10px 0;
    font-size: 1.2rem;
    line-height: 1.5;
    display: block;
    margin: 25px;
    color: black;
  }
}

/* Section-13 */
.section13 {
  margin: 4vw 0;
}

.section13-text {
  width: 70vw;
  padding-top: 20px;
}

.section13-sub_section {
  width: 84vw;
  margin: 4vw 0;
}

.section13-subheader_text {
  font-size: 30px;
  font-weight: bold;
}

.section13-content {
  display: flex;
  gap: 2vw;
  justify-content: space-between;
}

.section13-sub_content {
  margin: 1vw;
}

.section13-icon {
  background-color: var(--primary);
  width: 6vw;
  height: 6vw;
  min-width: 40px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1vw auto;
}

@media (max-width: 767px) {
  .section13-sub_section {
    display: none;
  }
}

/* Section-13 Mobile */
@media (max-width: 1000px) {
  .section13-content {
    display: none;
  }

  .section13-mobile-usecase-section {
    margin: 50px 0;
    display: block;
  }

  .section13-mobile-usecase-section {
    display: block;
    padding: 0 2vw;
  }

  .section13-mobile-usecase-item {
    margin: 20px 0;
  }

  .section13-mobile-usecase-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 20px 0;
  }

  .section13-mobile-usecase-title {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .section13-mobile-usecase-button {
    all: unset;
    cursor: pointer;
  }

  .section13-mobile-usecase-content {
    margin: 20px 0;
    padding: 0 10px;
    font-size: 1.2rem;
  }

  .section13-mobile-divider_black {
    height: 0.5px;
    background: #000000;
    margin: 10px 0;
  }
}

@media (min-width: 1001px) {
  .section13 {
    display: block;
  }

  .section13-mobile-usecase-section {
    display: none;
  }
}

/* Section-14 */
.section14 {
  margin: 4vw 0;
}

.section14-subheader_text {
  font-size: 40px;
  font-weight: bolder;
}

.section14-sub_section {
  margin: 2vw 0;
}

.section14-sub_title_text {
  font-size: 30px;
}

.section14-mobile-titleIcon {
  height: 50px;
  width: 100px;
  background: var(--primary);
  border-radius: 50px;
  padding: 10px;
}

.section14-header_text {
  font-size: 60px;
  font-weight: bold;
}

.section14-text {
  margin: 2vw 0;
}

/* Section-14 Mobile*/
@media (max-width: 768px) {
  .section14 {
    background-color: black;
    color: white;
    padding: 50px 30px 50px 30px;
  }

  .section14-sub_section {
    display: none;
  }

  .section14-mobile-content {
    display: block;
  }
}

/* Section-15 */
.section15 {
  padding: 1vw 5vw;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  background-color: #f3f3f3;
}

.section15-sub_section {
  margin: 4vw 0;
}

.section15-title {
  display: flex;
  align-items: center;
}

.section15-number {
  width: 4vw;
  height: 4vw;
  min-width: 40px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary);
  margin-right: 4vw;
}

@media (max-width: 767px) {
  .section15 {
    display: none;
  }
}

.section15-mobile {
  margin-top: 80px;
  margin-bottom: 80px;
}

Section-16 .section16 {
  margin: 4vw 0;
}

.section16-text {
  padding-top: 20px;
}

.section16-grid-row {
  display: contents;
}

.section16-subheader_text {
  font-size: 45px;
  font-weight: bold;
  margin-bottom: 10px;
}

.section16-desc {
  margin-bottom: 20px;
}

.section16-subsection {
  margin: 4vw 0;
}

.section16-sub_title_text {
  font-size: 35px;
  margin-bottom: 10px;
}

.section16-grid {
  display: block;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.section16-grid_item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.section16-left {
  grid-column: 1;
}

.section16-right {
  grid-column: 2;
}

.section16-grid_item.empty {
  visibility: hidden;
}

/* Mobile View */
@media (max-width: 768px) {
  .section16-grid {
    display: none; /* Hide grid on mobile screens */
  }

  .section16-mobile-usecase-item {
    display: block; /* Show mobile use case items on mobile screens */
    margin: 20px 0;
  }
}

@media (min-width: 769px) {
  .section16-grid {
    display: grid;
  }

  .section16-mobile-usecase-item {
    display: none;
  }
}

/* Section-17 */
.section17 {
  background-color: #f3f3f3;
  padding: 4vw 5vw;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
}

.section17-subheader_text {
  font-size: 45px;
  font-weight: bold;
}

.section17-text {
  margin: 2vw 0;
}

/* Section-18 */
.section18 {
  margin: 4vw 0;
}

.section18-text {
  padding-top: 20px;
  font-size: 45px;
  font-weight: bold;
  margin-bottom: 10px;
}

.section18-subheader_text {
  font-size: 45px;
  font-weight: bold;
  margin-bottom: 10px;
}

.section18-desc {
  margin-bottom: 20px;
}

.section18-grid_item.empty {
  visibility: hidden;
}

.section18-subsection {
  margin: 4vw 0;
}

.section18-sub_title_text {
  font-size: 35px;
}

.section18-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.section18-grid-row {
  display: contents;
}

.section18-grid_item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.section18-left {
  grid-column: 1;
}

.section18-right {
  grid-column: 2;
}

/* Mobile View */
@media (max-width: 768px) {
  .section18-grid {
    display: none;
  }

  .section18-mobile-usecase-item {
    display: block;
  }
}

@media (min-width: 769px) {
  .section18-grid {
    display: grid;
  }

  .section18-mobile-usecase-item {
    display: none;
  }
}

/* Section-19 */
.section19 {
  margin-bottom: 40px;
}

.section19-subheader_text {
  font-size: 45px;
  font-weight: bold;
  margin-bottom: 20px;
}

.section19-subtext {
  font-size: 45px;
  margin-bottom: 10px;
}

.section19-sub_content {
  flex: 1;
}

.section19-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.section19-grid-item {
  padding: 15px;
}

.section19-sub_content {
  flex: 1;
}

.section19-sub_title_text {
  font-size: 25px;
  font-weight: bold;
}

.section19-sub_title_desc {
  font-size: 22px;
}

/* Mobile View */
@media (max-width: 768px) {
  .section19-grid {
    display: none;
  }

  .section19-mobile {
    display: block;
  }
}

@media (min-width: 769px) {
  .section19-grid {
    display: grid;
  }

  .section19-mobile {
    display: none;
  }
}

/* Section-20 */
.section20 {
  margin: 8vh auto;
  width: 80%;
}

.section20-link {
  text-decoration: none;
  color: black;
}

.section20-button {
  margin: 6vh auto;
  width: 100%;
  display: flex;
  font-size: 40px;
  font-weight: bold;
  padding: 20px 40px;
  background-color: var(--primary);
  border-radius: 10px;
  justify-content: center;
  text-decoration: none;
  transition: all 0.3s;
  text-align: center;
}

.section20-button:hover {
  background-color: black !important;
  color: var(--background-white) !important;
  border-color: white !important;
}

.section20-subheader_text {
  font-size: 45px;
  font-weight: bold;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .section20 {
    width: 100%;
  }

  .section20-button {
    font-size: 24px;
    padding: 15px 20px;
  }

  .section20-subheader_text {
    font-size: 30px;
  }
}
