html,
body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    width: 100%;
    /* Ensures the body doesn't extend beyond 100% of the viewport width */
}


.home-root {
    background-color: black;
}

h1 {
    margin-left: 0px;
    margin-right: 0px;
    font-size: 1.2em;
}

body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin: 0;
}

.header {
    flex: 1;
    text-align: left;
    padding: 1.5em;
}

#hero-section {
    background-size: 100%;
    background-repeat: no-repeat;
    color: white;
}

.large1 {
    font-size: 1.2em;
}

.large2 {
    font-size: 1.2em;
    width: 100%;
}

.small-header {
    font-size: 0.7em;
    width: 100%;
}

.hero {
    color: var(--primary);
    width: 100%;
}

.navbar {
    height: 10vh;
}

.hero-sub {
    font-size: 0.7em;
    line-height: 150%;
    font-weight: 500;
    margin: 0 0 3em 0;
}

.green-button-container {
    background-color: var(--primary);
    text-align: center;
    margin-bottom: 1em;
    color: black;
    font-size: 0.7em;
    width: 82.5vw;
    height: 2.5em;
    font-weight: bold;
    border-radius: 10px;
    padding: 0.5em 0.5em;
    justify-content: center;
    align-items: center;
    transition: all ease-in-out 0.3s;
    cursor: pointer;

    &:hover {
        border: 1px solid var(--primary);
        background-color: black;
        color: var(--primary);
    }
}

.green-button {
    background-color: var(--primary);
    text-align: center;
    margin-bottom: 1em;
    color: black;
    font-size: 0.7em;
    width: 82.5vw;
    height: 2.5em;
    font-weight: bold;
    border-radius: 10px;
    padding: 0.5em 0.5em;
    justify-content: center;
    align-items: center;
    transition: all ease-in-out 0.3s;
    cursor: pointer;

    &:hover {
        border: 1px solid var(--primary);
        background-color: white;
        color: var(--primary);
    }
}

.orange-btn-container {
    background-color: transparent;
    text-align: center;
    margin-bottom: 1em;
    border: solid 1px #ff5b00;
    width: 100%;
    height: 2.5em;
    color: white;
    background-color: #ff5b00;
    font-size: 0.7em;
    font-weight: bold;
    border-radius: 10px;
    padding: 0.5em 0.5em;
    justify-content: center;
    align-items: center;
    transition: all ease-in-out 0.3s;
    cursor: pointer;

    &:hover {
        border: 1px solid #ff5b00;
        background-color: black;
        color: #ff5b00;
    }
}

.hero-image {
    width: 100%;
    margin-top: 3.5em;
    margin-bottom: 2em;
}

#section2 {
    background-color: white;
    padding: 1.5em 1.5em;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.turbocharger {
    margin: 0px 0px;
    transform: scale(0.7) translateY(-0.1em);
}

#section2>div {
    display: flex;
}

#turbocharger+h1 {
    font-size: 1em;
}

#section2 h5 {
    font-size: 0.8em;
    font-weight: 400;
    text-align: center;
}

.card-container {
    width: 100%;
    background-color: #fbfbfb;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    border-radius: 15px;
    box-shadow: 5px 0px 10px 1px rgba(0, 0, 0, 0.2), -5px 0px 10px 1px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    transition: max-height 0.3s ease, box-shadow 0.3s ease;
}

.card-expanded {
    max-height: none;
}

.card-image {
    width: 100%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-color: transparent;
}

.card-body {
    padding: 0.5em 1em;
    display: flex;
    flex-direction: column;
    /* color: white;
  background-color: black; */
}

.card-body-title {
    font-size: 0.8em;
    font-weight: 800;
    text-align: left;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    color: black;
}

.card-body-text {
    font-size: 0.6em;
    line-height: 1.5;
    max-height: calc(1.5 * 1em * 3);
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    transition: max-height 0.3s ease;
}

.card-expanded .card-body-text {
    max-height: none;
    display: block;
}

.card-body-footer {
    margin-top: 1em;
    font-size: 0.5em;
    color: #ff5b00;
    cursor: pointer;
}


.card-stack {
    display: flex;
    flex-direction: column;
}

.card-stack+div {
    margin-top: 1em;
    width: 100%;
}

#section3 {
    background-color: black;
    padding: 1.5em 1.5em;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: white;
}

.key-propositions {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1em;
    font-weight: bold;
    font-size: 0.7em;
}

.proposition {
    max-height: 0px;
    width: 80%;
    height: 100%;
    overflow: hidden;
    font-size: 0.8em;
    transition: all 0.8s ease-out;
    position: relative;
    left: 1.5em;
    margin: 0.5em 0.5em;
    font-weight: normal;
}

.expanded {
    overflow: visible;
    max-height: 200px;
    margin-left: 2em;
}

.accordion-container {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    font-size: 1em;
    font-weight: bold;
}

.accordion-title-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.accordion-logo {
    width: 8.5%;
    margin-right: 1em;
}

.accordion-title {
    margin-right: auto;
    /* Pushes the arrow to the right */
    flex-grow: 1;
    color: white;
}

.title-expanded {
    color: var(--primary);
    /* This will apply the primary color when expanded */
}

.up-arrow {
    margin-left: auto;
    width: 1.5em;
}

#section4 {
    background-color: white;
    padding: 1.5em 1.5em;
}

#section4>div {
    display: flex;
    justify-content: center;
}

#section4 h5 {
    margin: 0px 0px;
    font-size: 0.7em;
    font-weight: 500;
    text-align: center;
}

.flowImages {
    width: 100%;
    height: 100%;
    border-radius: 0.3em;
}

#section5 {
    display: flex;
    flex-direction: column;
    row-gap: 1em;
    background-color: var(--primary);
    padding: 1.5em 1.5em;
}

#section5 h1 {
    margin: 0px 0px;
    text-align: center;
}

#section5>div {
    display: flex;
    justify-content: center;
}

#section5 h5 {
    margin: 0px 0px;
    font-size: 0.65em;
    font-weight: 500;
    text-align: center;
}

#sign-up-form {
    display: flex;
    flex-direction: column;
    row-gap: 0.3em;
}

#sign-up-form input {
    width: 100%;
    font-size: 0.7em;
    padding-left: 0.5em;
}

#sign-up-form input[placeholder="Message"] {
    width: 100%;
    height: 100px;
}

.contact-us {
    background-color: black;
    text-align: center;
    margin-bottom: 1em;
    color: white;
    font-size: 0.7em;
    width: 100%;
    height: 2.5em;
    font-weight: bold;
    border-radius: 10px;
    padding: 0.5em 0.5em;
    justify-content: center;
    align-items: center;
    transition: all ease-in-out 0.3s;
    cursor: pointer;

    &:hover {
        background-color: var(--primary);
        -webkit-box-shadow: inset 0px 0px 0px 1px var(--primary);
        -moz-box-shadow: inset 0px 0px 0px 1px var(--primary);
        box-shadow: inset 0px 0px 0px 1px var(--primary);
        color: black;
        border: 1px solid black;
    }
}

.message-textarea {
    height: 150px;
    padding-left: 0.5em;
    font-size: 0.7em;
    border-radius: 5px;
    border: 1px solid #ccc;
    resize: none;
    /* prevents resizing by the user */
}

.primary {
    color: var(--primary);
    /* Assuming --primary is defined in your CSS variables */
}

.card-container-section4 {
    width: 100%;
    background-color: #fbfbfb;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    border-radius: 15px;
    box-shadow: 5px 0px 10px 1px rgba(0, 0, 0, 0.2), -5px 0px 10px 1px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    transition: max-height 0.3s ease, box-shadow 0.3s ease;
}

.card-container-section4.card-expanded {
    max-height: none;
}

.card-container-section4 .card-image {
    width: 100%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-color: transparent;
}

.card-container-section4 .card-body {
    padding: 0.5em 1em;
    display: flex;
    flex-direction: column;
    background-color: black;
    color: white;
}

.card-container-section4 .card-body-title {
    font-size: 0.8em;
    font-weight: 800;
    text-align: left;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    color: white;
}

.card-container-section4 .card-body-text {
    font-size: 0.6em;
    line-height: 1.5;
    max-height: calc(1.5 * 1em * 3);
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    transition: max-height 0.3s ease;
}

.card-container-section4.card-expanded .card-body-text {
    max-height: none;
    display: block;
}

.card-container-section4 .card-body-footer {
    margin-top: 1em;
    font-size: 0.5em;
    color: var(--primary);
    cursor: pointer;
}

.divider-line {
    height: 0.5px;
    background-color: white;
}

.card-icon {
    background-color: var(--primary);
    position: relative;
    width: 60px;
    height: 60px;
    margin-bottom: 1vh;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.3em;
}

.card-icon img {
    width: 60%;
    height: 60%;
}

.about-header {
    margin: 0.7em 0;
}

.parent-header {
    margin-bottom: 2em;
}

.parent-container {
    display: flex;
    align-items: left;
    justify-content: flex-start;
    /* Align icons to the left */
}

.tool-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%
}

.tool-item {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
}

.tool-title {
    font-size: 0.8em;
    font-weight: bold;
    color: white;
    margin-left: 1em;
}

h1 {
    display: block;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    unicode-bidi: isolate;
}

h5 {
    display: block;
    margin-block-start: 1.67em;
    margin-block-end: 1.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    unicode-bidi: isolate;
}

h4 {
    display: block;
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    unicode-bidi: isolate;
}

h3 {
    display: block;
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    unicode-bidi: isolate;
}

div {
    display: block;
    unicode-bidi: isolate;
}

img {
    overflow-clip-margin: content-box;
    overflow: clip;
}

.hide {
    opacity: 0;
    transform: translateY(2em);
    transition: transform 0.5s ease-in, opacity 0.5s ease-in;
}

.show {
    opacity: 1;
    transform: translateY(0);
    transition: transform 0.5s ease-in, opacity 0.5s ease-in;
}

.delay1 {
    transition-delay: 0.5s;
}

.delay2 {
    transition-delay: 0.2s;
}