/* Container for the entire form */
.update-job-container {
  width: 100vw;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid var(--background-grey);
  border-radius: 8px;
  background-color: var(--background-grey);
}

.update-create-job-title{
  font-size: 24px;        /* Set the font size */
  font-weight: bold;      /* Make the text bold */
  color: #333;            /* Set the text color */
  margin-bottom: 20px;    /* Add some space below the heading */
  text-align: center;     /* Center the text */
  letter-spacing: 1px;    /* Add some spacing between letters */
}

/* Form grid layout */
.update-job-form {
  display: grid;
  gap: 10px;
}

/* Label styles */
.update-job-form label {
  font-weight: bold;
  margin-bottom: 5px; /* Adds spacing between label and input field */
  display: block; /* Ensures the margin applies correctly */
}

/* Input fields styling */
.update-job-form input:not(textarea) {
  width: 35%;
  padding: 12px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s, box-shadow 0.3s;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

/* Focus state for input fields */
.update-job-form input:not(textarea):focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  outline: none;
}

/* Hover state for input fields */
.update-job-form input:not(textarea):hover {
  border-color: #0056b3;
}

.update-job-form button {
  width: 15vw;
  padding: 10px 20px;
  background-color: var(--primary); /* Use primary color */
  color: black !important;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.1rem; /* Consistent font size */
  font-weight: bold; /* Bold text */
  transition: background-color 0.3s, box-shadow 0.3s;
}

/* Hover state for button */
.update-job-form button:hover {
  background-color: black !important;
  color: var(--background-white) !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-color: white !important;
}

/* Error message styling */
.error-message {
  color: red;
  margin-top: 5px;
}

/* Error list styling */
.error-list {
  color: red;
  margin-top: 5px;
  list-style-type: none;
  padding-left: 0;
}

/* Error list items */
.error-list li {
  margin-bottom: 5px;
}

.update-job-form select,
.update-job-form input[type="text"],
.update-job-form input[type="date"] {
  width: 30%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
}

.update-job-form select:focus,
.update-job-form input[type="text"]:focus,
.update-job-form input[type="date"]:focus {
  border-color: #66afe9;
  outline: none;
  box-shadow: 0 0 8px rgba(102, 175, 233, 0.6);
}

.update-job-form select {
  background-color: #fff;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns%3D%22http://www.w3.org/2000/svg%22 viewBox%3D%220 0 4 5%22%3E%3Cpath fill%3D%22%23333%22 d%3D%22M2 0L0 2h4zM2 5L0 3h4z%22/%3E%3C/svg%3E');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 10px;
}

.update-job-form select option {
  padding: 10px;
}