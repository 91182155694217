/* Core Value Card */
.core-value-card {
  background-color: #000000; /* Black background */
  color: #FFFFFF; /* White text */
  border-radius: 15px; /* Rounded corners */
  padding: 20px; /* Padding inside the card */
  width: 100%; /* Full width of the container */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.core-value-card-wrapper {
  display: flex;
  align-items: stretch; /* Ensure all cards stretch to the same height */
}

.core-value-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.core-value-icon {
  width: 50px; /* Icon size */
  height: 50px; /* Icon size */
  margin-bottom: 10px; /* Space between icon and text */
}

.core-value-title {
  font-size: 20px; /* Title font size */
  font-weight: bold; /* Bold title */
  margin-bottom: 10px; /* Space below the title */
}

.core-value-description {
  font-size: 14px; /* Description font size */
  line-height: 1.6; /* Line height for better readability */
  color: #E0E0E0; /* Slightly lighter text color */
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .core-value-card {
    height: auto; /* Ensure cards adjust height automatically on mobile */
  }

  .core-value-card-wrapper {
    height: auto; /* Ensure wrappers adjust height automatically on mobile */
  }

  .block-container h1 {
    font-size: 1.2em !important; /* Adjust font size to fit on one line */
    white-space: nowrap !important; /* Prevent text from wrapping to the next line */
    width: 100%; /* Use full width of the container */
    text-align: center; /* Center-align text */
  }

  /* Separate h3 elements */
  .pledge h3 {
    display: block;
    width: 100%;
    margin: 5px 0;
  }
}
