.pricing_container {
  margin: 80px 0;
}

.pricing_header{
  font-weight: bold;
  font-size: 60pt;
  font-family: "Neue Haas Grotesk Display Pro" !important;
}

.pricing_container > div {
  padding-inline: 10vw;
}

.pricing_container2 h3,
.pricing_container3 h3,
.pricing .tier-section h4,
.pricing .faq h3,
.pricing .info-section h3,
.pricing .contact-section h3,
.pricing .onboard-section strong,
.pricing .onboard-section h3 {
  color: var(--secondary);
}

.pricing_text_container div {
  padding: 2vh 0;
}

.pricing_container1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8vw;
}

.pricing_text_container {
  flex: 0.5;
}

.pricing_img_container {
  flex: 0.5;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.pricing_img_container img {
  width: 100%;
  height: auto;
  max-width: 800px;
}

.pricing_container2 {
  padding: 8vh 0;
  width: 90%;
}

.pricing_container2 h3 {
  font-size: 35px;
}

.pricing_container2 h4 {
  font-size: 40px;
}

.pricing_container2 h4 {
  padding-block: 1vh;
  font-weight: semibold;
}

.pricing_container3 {
  background-color: var(--background-grey);
  padding: 8vh 10vw;
}

.pricing_container3 h3 {
  padding-bottom: 2vh;
  font-size: 35px;
}

.pricing .tier-section {
  padding-top: 20px;
}

.pricing .tier-selected {
  background-color: #dad7d7 !important;
}

.pricing .tier-section > div {
  margin-bottom: 30px;
}

.pricing_container4 {
  background-color: black;
  color: white;
  padding: 20px 10vw;
}

.pricing_plan_title {
  padding-top: 8vh;
  padding-bottom: 1vh;
  font-size: 35px;
  font-family: "Neue Haas Grotesk Display Pro";
}

.pricing_plan_sub_title {
  padding: 2vh 0;
  font-family: "Neue Haas Grotesk Display Pro";
}

.pricing-list {
  list-style-type: none;
  padding: 0;
}

.pricing-list li {
  margin-bottom: 10px;
  position: relative;
  display: flex;
  align-items: center;
  color: white;
}

.pricing-list li img {
  margin-right: 0.6vw;
  width: 22px;
  height: 22px;
}

.pricing-list li span {
  color: white;
}

.pricing_container .subheader1_text {
  color: black;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
}

.pricing .info-section,
.pricing .onboard-section,
.pricing .contact-section {
  padding-top: 20px;
}

.pricing .contact-section div,
.pricing .info-section div,
.pricing .onboard-section p {
  margin: 20px 0;
}

@media (max-width: 768px) {
  .pricing h1 {
    font-size: 2em;
  }

  .pricing h2,
  .pricing h3 {
    font-size: 1.5em;
  }
}

.pricing span {
  color: black;
}

.pricing .faq {
  display: flex;
  flex-direction: column;
  padding-block: 8vh;
}

.pricing .faq h3 {
  padding-bottom: 4vh;
  font-size: 40px;
}

.pricing .faq .head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 20px 0;
}

.pricing .faq .content {
  margin: 20px 0;
}

.pricing .divider_black {
  height: 0.5px;
  background: #000000;
}

.pricing .contact-section,
.pricing .info-section,
.pricing_img_container1,
.pricing .onboard-section {
  width: 60vw;
}

.pricing_img_container1 {
  padding: 4vh 0;
}

.pricing_img_container1 img {
  width: 100%;
  height: auto;
}

.pricing .onboard-section ul {
  padding: 2vh 0;
}

.pricing .onboard-section ol {
  padding-top: 2vh;
}

.pricing_onboard_ul {
  list-style: disc;
}

.pricing .faq .faq-question,
.pricing .onboard-section strong
{
  font-family: "Neue Haas Grotesk Display Pro";
}
.onboard-section .collapsible-box {
  margin-bottom: 30px;
  margin-top:30px; /* Adjust this value to set the desired spacing */
}

@media (max-width: 768px) {
  .pricing_header {
    font-size: 60px;
  }

  .pricing_container1 {
    flex-direction: column;
    gap: 0;
    width: 100%;
  }

  .pricing_text_container .subheader_text {
    font-size: 24px;
    padding: 2vh 0;
    line-height: 125%;
  }

  .pricing_text_container div,
  .pricing_container2 div,
  .pricing .contact-section div,
  .pricing .info-section div,
  .pricing .onboard-section div,
  .pricing .onboard-section ol,
  .pricing .onboard-section ul,
  .pricing_container4 .pricing-list li {
    font-size: 16px;
    line-height: 140%;
  }

  .pricing_img_container {
    justify-content: center;
    align-items: center;
  }

  .pricing_container2 {
    width: 100%;
  }

  .pricing_container2 h3,
  .pricing_container2 h4,
  .pricing_container3 h3,
  .pricing .contact-section h3,
  .pricing .info-section h3,
  .pricing .onboard-section h2,
  .pricing .onboard-section h3 {
    font-size: 18px;
  }

  .pricing .faq h3,
  .pricing_container4 .pricing_plan_title {
    font-size: 24px;
    line-height: 125%;
  }

  .pricing .faq .faq-question,
  .pricing .faq .content,
  .pricing_container4 .pricing_plan_sub_title {
    font-size: 18px;
  }

  .pricing .contact-section,
  .pricing .info-section,
  .pricing .onboard-section,
  .pricing_img_container1 {
    width: 100%;
  }

  .pricing-list li img {
    margin-right: 2vw;
  }
  .onboard-section .subheader_text {
    font-weight: bold;
    color: black; /* Ensures subheaders are bold and black */
  }

  .onboard-section h2.subheader_text {
    font-size: 1.4rem; /* Adjust this value to make it bigger */
    line-height: 1.2; /* Adjust line height for better readability */
  }
  .onboard-section ul {
    margin-left: 5px; /* Push bullet points to the right */
  }

  .onboard-section h3.subheader_text {
    font-size: 1rem; /* Ensure subheaders are bold and sized properly */
    margin-top: 1rem;
    color: black;
    font-weight: bold; /* Optional: Add some spacing for clarity */
  }

  .info-section h3.subheader_text {
    font-size: 1.4rem;
    color: black;
    font-weight: bold;
  }

  .onboard-section .beat-the-clock-heading{
    margin-bottom: 10px;
  }
  .onboard-section .beat-the-clock-description {
    margin-left: 5px; /* Add space above the description div */
  }
  .onboard-section .secure-spot{
    margin-bottom: 10px;
    margin-top: 10px;
  }


  .onboard-section ul {
    margin-left: 20px; /* Ensure all bullet points are consistently aligned */
    padding-left: 20px; /* Add padding to align bullets and text */

  }
  .onboard-section .secure-spot{
    margin-bottom: 10px;
  }
  .onboard-section .mobile-fix{
    margin-bottom: 10px;
  }

}
