.checkoutForm {
    padding: 20px;
    display: flex;
    justify-content: center;
    column-gap: 50px;
}

.medTopPadding {
    padding-top: 25px;
}

.formContainer {
    display: flex;
    width: 55%;
    flex-direction: column;
    justify-content: center;
}

.formSection {
    display: flex;
    padding: 15px;
    flex-direction: column;
    row-gap: 10px;
    margin-bottom: 25px;
    border-radius: 10px;
}

.small-border {
    border: 1px black solid;
}

.largeWidth {
    width: 95%;
}

.grey-bkg {
    background-color: var(--background-grey)
}

.white-bkg {
    background-color: var(--background-white);
}

li span {
    color: black;
    flex-direction: column;
    row-gap: 10px
}

.red {
    color: red
}

.confirmPurchaseContainer h4 {
    text-align: center;
    padding-top: 5px;
}

.confirmPurchaseContainer p {
    padding-left: 10px;
}

.smallMarginVert {
    margin: 10px 0px;
}

.confirmPurchaseContainer {
    width: 35%;
    /* For compatability */
    height: -webkit-fit-content;
    height: fit-content;
}

.purchaseButton {
    width: 90%;
    display: block;
    margin: 0 auto 10px auto;
}

.lightBold {
    font-weight: 500;
}

.line {
    height: 1px;
    background-color: var(--background-grey);
}

/* Successfull Page */
.success-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-bottom: 300px;
  }
  
  .success-content {
    text-align: center;
    margin: 50px;
    padding: 45px;
    border-radius: 8px;
    box-shadow: 4px 3px 7px 2px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 100%;
  }
  
  .checkmarkImg {
    width: 100px;
  }
  
  .success-heading {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .success-text {
    font-size: 18px;
    margin-bottom: 10px;
    color: #222222;
  }
  
