.landing-page {
  /* padding-top: 20px; */
  font-family: "Neue Haas Grotesk Display Pro";
}

.landing-page_section-white {
  background-color: var(--background-white);
}

.landing-page_section-grey {
  background-color: var(--background-grey);
}

.landing-page .center,
.landing-page .center2 {
  display: flex;
  text-align: center;
  flex-direction: column;
}

.newcenter2 {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  text-align: left;
}

.newcenter3 {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  padding: 10px;
}

.newcenter_earlyAccess {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  padding: 10px;
  margin: 20px;
}

.small h2 {
  font-size: 21px;
  font-weight: bold small;
  margin: 20px;
}

.newcontainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.newcontainerbox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  padding-top: -10px;
  width: 50%;
}

.boxcontainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
}

.newcontainer > * {
  flex: 1 1 0;
}

.landing-page .container {
  position: relative;
  margin: 30px 0;
  text-align: center;
  background-color: transparent;
}

.landing-page .center {
  align-self: baseline;
}

.landing-page .center2 {
  width: 60vw;
  margin: 0 auto;
}

.landing-page .center3 {
  display: flex;
  /* justify-content: center; */
}

.landing-page_title {
  background-color: var(--primary) !important;
  padding: 5vh 0px;
  padding-left: 5vh;
}

.landing-page_subtitle {
  padding: 20px 0px;
}

.landing-page_subtext {
  text-align: justify;
  max-width: 80%;
}

.landing-page_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.landing-page .overview {
  display: flex;
  column-gap: 80px;
  padding: 20px 10vw;
}

.landing-page .MuiButton-root {
  margin: 20px auto 0 auto !important;
  /* width: fit-content !important; */
  /* padding: 0 3vw !important; */
}

.key_takeaways {
  display: flex;
  align-items: center;
  text-align: left;
  justify-content: flex-start;
  width: 100%;
  padding: 10px;
}

.key_takeaways p {
  margin: 10px 0;
}

.key_takeaways_square {
  width: 2vw;
  height: 2vw;
  background-color: var(--primary);
  margin-right: 40px;
}

.border-elem {
  position: relative;
  width: 30vw;
  padding: 30px;
  border: 2px solid transparent;
  margin: 20px 0;
  align-self: center;
  border-radius: 30px;
}

.border-elem::before,
.border-elem::after {
  content: "";
  position: absolute;
  background-color: transparent;
}

.border-elem::before {
  top: -2px;
  right: 0;
  height: 50%;
  width: 50%;
  border-top: 4px solid #ff922d;
  border-right: 4px solid #ff922d;
  border-top-right-radius: 30px;
}

.border-elem::after {
  bottom: 0;
  left: -2px;
  width: 50%;
  height: 50%;
  border-left: 4px solid #01f8d4;
  border-bottom: 4px solid #01f8d4;
  border-bottom-left-radius: 30px;
}

@media (max-width: 768px) {
  .border-elem {
    width: 100%;
    /* Full width for mobile screens */
  }
}

/* Responsiveness */
@media (max-width: 768px) {
  .landing-page .center,
  .landing-page .center2,
  .newcenter2,
  .newcenter3,
  .newcenter_earlyAccess,
  .small,
  .boxcontainer,
  .newcontainer,
  .newcontainerbox,
  .landing-page-formTextBox,
  .landing-page-icon_container {
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 768px) {
  .landing-page_formTextBox,
  .formButton {
    width: 90%; /* Slightly smaller width for padding */
  }

  .landing-page_subtitle,
  .landing-page_subtext {
    font-size: smaller;
  }

  .landing-page-icon_container {
    padding: 20px;
  }
}

.border-elem h4 {
  margin: 10px 0 !important;
}

.border-elem .title {
  margin: 0;
}

.border-elem .key_takeaways {
  margin: 0 auto;
  width: fit-content;
}

.border-elem .key_takeaways_square {
  background-color: transparent;
}

.left {
  /* margin-left: 5vw; */
  align-self: flex-start;
}

.right {
  align-self: flex-end;
}

.early-access {
  display: flex;
  background-color: var(--primary);
  padding-bottom: 30px;
}

.font1,
.font2,
.font3,
.font4 {
  font-family: "Neue Haas Grotesk Display Pro";
  font-family: unset !important;
}

.landing-page .font1 {
  font-size: calc(3vw + 3vh);
  font-size: unset !important;
}

.landing-page .font2 {
  font-size: calc(2vw + 2vh);
  font-size: unset !important;
}

.landing-page .font3 {
  font-size: calc(1vw + 1vh);
  font-size: unset !important;
}

.landing-page .font4 {
  font-size: calc(0.7vw + 0.7vh);
  font-size: unset !important;
}

.landing-page-formTextBox {
  width: 100%;
  background-color: var(--background-grey);
  border: var(--background-white);
  font-family: "Neue Haas Grotesk Display Pro";
  font-size: calc(0.45vw + 0.6vh);
  height: 40px;
  border: 1px solid #afacac;
  padding-left: 10px;
}

.landing-page-formField {
  margin: 10px;
}

.landing-page_container .form-container {
  padding-top: 20px;
  width: 50%;
}

.formButton {
  background-color: var(--primary);
  border: none;
  border-radius: 10px;
  color: black;
  font-weight: bold;
  font-size: 15px;
  transition: all 0.3s;
  text-align: center;
  width: 180px;
  height: 47px;
}

.mobileformButton {
  @apply bg-primary border-none rounded-[15px] text-black font-bold text-[17px] transition-all duration-300 text-center w-full h-[47px];
}

.error {
  font-size: small;
  margin-top: 2px;
}

.checkbox-container {
  display: flex;
  align-items: center;
}

.checkbox-container input[type="checkbox"] {
  margin-right: 10px;
  width: 24px;
  height: 24px;
}
