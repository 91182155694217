@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --primary: #01f6d3;
  --secondary: #696969;
  --accent: #ff5b00;
  --background-grey: #f3f3f3;
  --background-white: #ffffff;
  --warning: #ff0000;
}

@font-face {
  font-family: "Neue Haas Grotesk Display Pro";
  src: url("./assets/fonts/NeueHaasDisplayMediu.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Neue Haas Grotesk Display Pro";
  src: url("./assets/fonts/NeueHaasDisplayBold.ttf") format("ttf");
  font-weight: 700;
  font-style: normal;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInAnimation {
  animation: fadeIn 0.5s ease-out forwards;
}

body,
.app,
select {
  margin: 0;
  padding: 0;
  font-size: 24px;
  /* font-family: "Neue Haas Grotesk Display Pro"; */
  font-family: "Inter";
}

.white {
  color: white;
}

.green {
  color: var(--primary);
}

.bold {
  font-weight: 700;
}

.semi-bold {
  font-weight: 500;
}

.underline {
  text-decoration: underline;
}

.normal {
  font-weight: 400;
}

.large {
  font-size: 50px;
}

.large2 {
  font-size: 2vw;
}

.large3 {
  font-size: 37px;
}

.medium {
  font-size: 30px;
}

.medium2 {
  font-size: 25px;
}

.btn_link {
  margin: 20px 0px;
  text-decoration: none;
}

.green_btn {
  background-color: var(--primary);
  border: none;
  border-radius: 5px;
  color: black;
  font-weight: bold;
  padding: 10px 35px;
  font-size: 20px;
  transition: all 0.3s;
  text-align: center;
}

.green_btn:hover {
  background-color: black;
  border: none;
  border-radius: 5px;
  color: var(--primary);
  font-weight: bold;
  padding: 10px 35px;
  font-size: 20px;
  cursor: pointer;
}

.small {
  font-size: 17px;
}

.primary-btn {
  background-color: var(--primary) !important;
  color: black !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  transition: all 0.3s;
  border: 1px solid !important;
  border-color: transparent !important;
  text-transform: none !important;
}

.primary-btn:hover {
  background-color: black !important;
  color: var(--background-white) !important;
  border-color: white !important;
}

.secondary-btn {
  background-color: var(--secondary) !important;
  font-weight: 600 !important;
  font-size: 20px !important;
}

.secondary-btn:hover {
  background-color: black !important;
  border: solid 1px white;
}

.tirtiary-btn {
  border: 1px solid var(--background-white) !important;
  color: var(--background-white) !important;
  font-weight: 600 !important;
}

.secondary-btn {
  background-color: var(--secondary) !important;
  font-weight: 600 !important;
}

.tirtiary-btn {
  border: 1px solid var(--background-white) !important;
  color: var(--background-white) !important;
  font-weight: 600 !important;
}

.alternate-btn {
  background-color: black !important;
  color: var(--primary) !important;
  font-weight: 600 !important;
  padding: 5px 15px !important;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s;
}

.alternate-btn:hover {
  background-color: var(--primary) !important;
  color: black !important;
}

.small {
  font-size: 16px;
}

.left {
  text-align: left;
}

.centered {
  margin: 0 auto;
}

.centerText {
  text-align: center !important;
}

.leftText {
  text-align: left;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.centerAligned {
  align-items: center;
}

.clickable {
  cursor: pointer;
}

input[type="checkbox"]:checked {
  accent-color: #182c3c;
}

.header_text {
  font-family: "Neue Haas Grotesk Display Pro";
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.subheader_text {
  font-family: "Neue Haas Grotesk Display Pro";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
}

.sub_title_text {
  font-family: "Neue Haas Grotesk Display Pro";
  font-size: 30px;
  font-style: normal;
  margin: 10px 0;
}

.subheader2_text {
  color: var(--Background, #696969) !important;
  font-family: "Neue Haas Grotesk Display Pro";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
}

.center_text {
  text-align: center;
}

.green_btn a,
.green_btn a:visited,
.green_btn a:hover,
.green_btn a:active,
.footer .links2 a,
.footer .links2 a:visited,
.footer .links2 a:hover,
.footer .links2 a:active {
  color: inherit;
}

.home_btn1 {
  color: black;
  text-decoration: none;
  transition: all 0.3s;
}

.home_btn1:hover {
  color: white !important;
}

.home_btn2 {
  color: white;
  text-decoration: none;
}

.text,
.normal_text {
  font-size: 20px;
}

.normal_text {
  font-weight: bold;
}
