/* Container styling for job list */
.job-list-container {
  margin: 0;
  padding: 20px;
  border: 0.5px solid transparent;
  border-radius: 8px;
  box-sizing: border-box;
}

.job-list-title {
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif; /* Matches the font family used in other elements */
  font-size: 2.5rem; /* Larger font size for emphasis */
  font-weight: bold; /* Makes the title stand out */
  color: black; /* Ensures the title color matches the overall theme */
  text-align: center; /* Centers the title horizontally */
  margin-bottom: 30px; /* Adds space between the title and the content below */
}

.filter-section {
  margin-bottom: 20px;
}

.filter-section h3 {
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif; /* Matches the font used in other sections */
  font-size: 1.8rem; /* Slightly larger font size for the heading */
  font-weight: bold; /* Makes the heading stand out */
  color: black; /* Matches the overall text color scheme */
  margin-bottom: 15px; /* Adds spacing between the heading and the filter buttons */
  text-align: left; /* Centers the heading within the filter section */
}

.filter-section button {
  margin-right: 10px;
  padding: 10px 20px;
  border: none;
  background: var(--primary);
  color: black;
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
  font-weight: 600;
  font-size: 17.7778px;
  cursor: pointer;
  width: fit-content;
}

.filter-section button.active {
  background: black;
  color: white;
}

.job-table-container {
  padding: 10px;
  text-align: left;
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
  font-size: 18px;
  color: black;
}
	

.job-table {
  width: 100%;
  border-collapse: collapse;
}

.job-table th, .job-table td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  text-align: left;
  font-family: 'Noto Serif Thai', serif;
  font-size: 18px;
  color: black;
}

.job-table th {
  background: var(--primary);
  font-weight: 600;
}

.job-table tr {
  cursor: pointer;
}



.job-table tr:hover {
  background: #f1f1f1;
}

/* Style for buttons in the action column */
.job-table .action-buttons {
  display: flex;
  gap: 10px;
  align-items: center;
}

.job-table .action-buttons button,
.job-table .action-buttons a {
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.job-table .action-buttons button {
  background: #FF4C4C;
  color: white;
}

.job-table .action-buttons button:hover {
  background: #D43F3F;
}

.job-table .action-buttons a {
  background: var(--primary);
  color: black;
  text-decoration: none;
  padding: 6px 12px;
  border: 1px solid var(--primary);
}

.job-table .action-buttons a:hover {
  background: var(--primary);
  border-color: var(--primary);
}

.error-message {
  color: red;
  margin-bottom: 20px;
}

.create-link {
  width: 15%;
  display: block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: var(--primary);
  color: black;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  font-size: 1.1rem;
  font-weight: bold;
  transition: background-color 0.3s, box-shadow 0.3s;
}

/* Hover state for Create New Job button */
.create-link:hover {
  background-color: black;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.logout-button-container {
  position: relative; /* Allows the button to be positioned absolutely within this container */
  width: 100%; /* Ensures the container spans the full width */
  text-align: right; /* Aligns the button to the right */
  margin-bottom: 20px; /* Adds some space below the button */
}

.logout-button {
  padding: 10px 20px;
  background-color: var(--primary);
  color: black;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  width: fit-content;
}

.logout-button:hover {
  background-color: black;
  color: white;
}