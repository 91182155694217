.career-job-detail-container {
  position: relative;
  width: 100vw;
  background-color: var(--background-grey);
  line-height: 200%;
  justify-content: left;
}

.job-detail-content {
  position: absolute;
  width: 1277px;
  height: 1139px;
  left: 50%;
  top: 80px;
  transform: translateX(-50%);
}

.job-title {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  line-height: 32px;
  color: #191919;
  margin-top: 20px;
  left: 120px;
  padding: 15px;
  margin-left: 15px;
}

.job-info {
  margin-bottom: 20px;
}

.info-heading {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 24px;
  color: #191919;
  margin-top: 30px;
  margin-bottom: 10px;
}

.info-text {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 19px;
  color: #1C1C1C;
}

.job-info ul {
  list-style-type: disc;
  margin-left: 20px;
}

.job-info ol {
  list-style-type: decimal;
  margin-left: 20px;
}

.apply-btn-container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  padding: 15px;
}

.apply-btn-container Button {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: whitesmoke; /* White text color */
  background: var(--primary); /* Greenish background color */
  padding: 15px 50px;
  border: none;
  text-decoration: none;
}

.apply-btn-container Button:hover {
  background: black;
  background: black;
  color: #FFFFFF; /* White text color on hover */
}
.go-back-container {
  display: flex;
  justify-content: flex-start; /* Align items to the left */
  padding: 15px;
}

.go-back-container Button {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: whitesmoke; /* White text color */
  background: var(--primary); /* Greenish background color */
  padding: 15px 55px;
  border: none;
  text-decoration: none;
}