
  .header-internal {
    height: fit-content;
    background-color: var(--background-grey);
  }

  
  .header-icon {
    height: 40px;
    margin-right: 20px;
  }
  
  .header-field {
    margin-right: 20px;
  }
  
  .login-header {
    background-color: #ffffff; 
  }
  
  .login-header .header-field {
    display: none; 
  }
  