.job-card {
    border: 1px solid #e0e0e0;
    border-radius: 15px;
    padding: 10px !important; /* Minimal padding */
    text-align: left;
    width: 90%;
    max-width: 350px;
    margin: 10px auto;
    background-color: hsl(0, 0%, 97%);
    flex-direction: column;
    gap: 3px !important; /* Smaller gap for closer content */
    box-sizing: border-box;
    position: relative;
}




.status-icon {
    width: 120px;
    height: 60px;
    margin:0;
    padding: 10px;
}
.secondary-icon {
    width: 40px; /* Adjusted size for closer appearance */
    height: 40px;
    padding: 0;
    margin-left: 10px;
    line-height: 1.1;
    
}



.job-title {
    font-size: 1px; /* Standard font size for job title */
    font-weight: bold;
    color: #000000;
 
    text-align: left;
    line-height: 1.2; 
    white-space: nowrap; /* Keeps the text on a single line */
    overflow: hidden; /* Hide any overflow text */
    text-overflow: ellipsis; /* Adds ellipsis (...) if the text is too long */
    display: block; 
    max-width: 100%; /* Ensures it does not exceed the width of the parent container */
    box-sizing: border-box; 
    margin-left: 5px; 
}

.job-subtitle {
    font-size: 14px;
    color: #000;
    line-height: 1.2;
    white-space: pre-wrap;
    margin-left: 10px; 
    text-align: left;
    padding-left: 15px;
    margin-top: 5px; 
    padding-bottom: 15px;

}

.job-link {
    font-size: 14px;
    font-weight: bold;
    color: #000;
    text-align: right; /* Align button to the right */
    position: absolute; /* Position relative to the parent container */
    bottom: 8px; /* Closer to the bottom */
    right: 8px; /* Closer to the right */
    padding-top: 5px;
    padding-right: 10px;
    margin-top: 10px; 
}

.job-link a {
    color: black;
    text-decoration: none;
}

.job-link a:hover {
    text-decoration: underline;
}
