.customer-services{
    margin: 0 auto;
}

.customer_service_title {
    margin: 0 auto;
    background-color: var(--primary);
    padding: 100px 0px;
    width: 80vw;
}

.customer-services .section{
    width: 36vw;
}

.customer-services .section2{
    width: 36vw;
    margin-left: 40vw;
}

.customer-service-link {
    color: black;
    text-decoration: underline;
    text-decoration-color: var(--primary);
}