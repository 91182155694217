.blog {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin: 0 5vw;
}

.blog-header {
    background-color: var(--primary);
    padding: 70px 0px;
    width: 100%;
    font-weight: 750;
}

.blog-subheading-text {
    padding: 1vw 0;
    align-self: flex-start;
    font-size: 1.6rem;
}

.justify-content-center {
    justify-content: center !important;
}

.blog-promo-wrapper {
    height: 80vh;
    margin-top: 4vw;
}

.align-self-center {
    align-self: center !important;
}

.blog-promo {
    height: 90vh;
    background-color: black;
    color: white;
}

.flex-column {
    flex-direction: column !important;
}

.blog-promo-header {
    font-size: 1.7rem;
    font-weight: 750;
}

.blog-promo-header + .blog-promo-header {
    margin-top: 40px;
}

.btn_link {
    margin: 20px 0px;
    text-decoration: none;

}

.promo-button-wrapper {
    width: max-content;
}

.promo-green-button {
    font-family: Inter;
    padding: 10px 35px !important;
    font-size: 1rem;
}

.promo-green-button a {
    text-decoration: none !important;
}

@media (min-width: 1441px) {
    .blog-header {
        padding: 120px 0px;
    }

    .blog-subheading-text {
        padding: 1rem 0;
        font-size: 2.5rem;
    }

    .blog-promo-header {
        font-size: 2.9rem;
        font-weight: 750;
    }

    .blog-promo,
    .blog-promo-wrapper {
        height: 60vh;
    }

    .promo-green-button {
        font-size: 1.8rem;
    }

}

@media (max-width: 768px) {
        .blog-subheading-text {
        padding: 1rem 0;
        font-size: 24px;
            letter-spacing: -0.05em;

    }
}
