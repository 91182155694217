.product {
  padding: 0 10vw;
}

.product .container1 {
  text-align: left;
  padding-top: 2vw;
}

.product .centered-text {
  color: black;
  width: 60vw;
  gap: 24px;
  display: flex;
  flex-direction: column;
}

.product-introduction {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.product-introduction-img {
  display: flex;
  margin-top: -18%;
}

.product-introduction .text2 {
  padding-top: 18px;
  width: 110vw;
}

.product .centerAlign {
  display: flex;
  justify-content: center;
}

.product-introduction img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  border: 0;
}

.product .container2 .text1 {
  width: 54vw;
  padding-top: 24px;
}

.product .container3 .text3 {
  width: 80vw;
  margin: 20px auto;
}

.product .container3 .subcontent1 .row {
  display: flex;
  width: 85vw;
  margin: 0 auto;
}
.product .container3 .subcontent1 .section {
  padding: 20px;
  margin: 10px;
  text-align: left;
  border: 1px solid black;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: var(--background-grey);
}

.product .container3 .additional-content .subcontent2 {
  width: 70vw;
  margin: 0 auto;
}

.product .container3 .additional-content .subcontent2 div {
  text-align: left;
  padding: 20px;
  margin: 20px;
  margin-bottom: 20px;
  border-left: 4px solid #000000;
}

.product .container3 .additional-content .subcontent3 {
  width: 80vw;
  margin: 0 auto;
}

.product .container3 .additional-content .subcontent3 .feature-highlight {
  padding: 10px 15px;
  margin: 10px 0;
  border-radius: 4px;
  border-radius: 10px;
  border: solid var(--primary) 2px;
}

.product .container3 .additional-content .subcontent3 .subtitle {
  font-weight: bold;
  margin-bottom: 5px;
}

.product .container3 .additional-content .subcontent3 ul li:before {
  content: "";
  color: black;
  font-weight: bold;
}

.product .container3 .subcontent4 {
  padding: 20px 16vw;
}

.product .container3 .subcontent4 .row div {
  background-color: var(--background-grey);
  border-radius: 10px;
  margin: 20px;
  padding: 20px;
  text-align: left;
}

.product .container4 .text2 {
  margin: 40px auto;
}

.product .features {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
  padding: 50px 0;
}

.product .key-feature-text div {
  margin: 10px 0;
}

.product .feature-icon {
  width: 5vw;
}

.product .divider_black {
  height: 0.5px;
  background: #000000;
}

.product .container5 {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.product .container5 .head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 20px 0;
}

.product .container5 .content {
  margin: 20px 0;
}

.product .container6 {
  background: #f3f3f3;
  margin: 60px calc(-10vw - 20px) 0 calc(-10vw - 20px);
  padding: 60px 10vw;
}

.product .container6 .sub_section1 {
  width: 60vw;
  display: flex;
  flex-direction: column;
}

.product .sub_section1_text {
  padding-top: 10px;
}

.product .sub_text_section4{
  padding-top: 10px;
}

.product .container6 .sub_section1 .text1 {
  margin-top: 30px;
}

.product .container6 .sub_section2 {
  margin: 30px auto;
  align-items: left;
  width: 80vw;
}

.product .container6 .sub_section2 .list {
  margin: 30px auto;
}

.product .container6 .sub_section2 .list .head {
  margin: 10px auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.product .container6 .sub_section2 .list .head .number {
  margin-right: 10px;
  width: 30px;
  height: 30px;
  background-color: var(--primary);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product .container6 .sub_section2 .list .content {
  margin-left: 40px;
}

.product .container6 .sub_section3 .large2 {
  text-align: left;
  margin: 30px 0;
}

.product .content {
  display: flex;
  justify-content: space-between;
}

.product .text_content {
  flex: 1;
}

.product .image_container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product .image_container img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

.product .container6 .sub_section3 .content .sub_content {
  margin: 10px 0;
}

.product .container6 .sub_section4 {
  display: flex;
  justify-content: space-between;
}

.product .container6 .sub_section4 .section_left {
  padding: 10px 10px 10px 0;
}

.product .container6 .sub_section4 .section_right {
  padding: 10px 0 10px 10px;
}

.product .container6 .sub_section4 .box {
  background-color: white;
  margin: 20px 0;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.product .container6 .list_container {
  margin: 30px auto;
  align-items: left;
}

.product .container6 .list_container .list {
  margin: 30px auto;
}

.product .container6 .list_container .list .head {
  margin: 10px auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.product .container6 .list_container .list .head .number {
  margin-right: 10px;
  width: 30px;
  height: 30px;
  background-color: var(--primary);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product .container6 .list_container .list .content {
  margin-left: 40px;
}

.product .container6 .sub_section4 img {
  width: 40vw;
}

.product .container6 .sub_section4 .text1 {
  margin: 10px 0;
}

.product .container7 {
  background: #000000;
  margin: 0 calc(-10vw - 20px);
  padding: 2vw 10vw;
}

.product .container7 .text1 {
  margin-top: 2vw;
}

.product .container7 .text2 {
  text-align: left;
  width: 60vw;
  margin-top: 3vw;
}

.product .container7 .content {
  display: flex;
  justify-content: space-between;
  column-gap: 150px;
}

.product .container7 .content .left_content,
.product .container7 .content .right_content {
  width: 40vw;
}

.product .sub_content {
  width: 35vw;
  margin-top: 2vw;
}

.product .container7 .content2 {
  padding-top: 40px;
  display: flex;
  justify-content: space-between;
  width: 90vw;
}

.product .container7 .content2 img {
  width: 50vw;
}

.product .content2_text{
  padding: 20px 0;
}

.product .section_button {
  all: unset;
  cursor: pointer;
}

.product .box > .alternate-btn {
  margin: 10px 0;
}

.product .subcontent3 ul li:before {
  content: "";
  color: black;
  font-weight: bold;
}

.product .subcontent3 ul li::marker {
  content: "";
  color: black;
  font-weight: bold;
}
