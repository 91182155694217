.resource_container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
  }
  
  .resource_container h1, .resource_container h2, .resource_container h3 {
    color: black;
  }
  
  .resource_container p {
    line-height: 1.6;
    color: #333;
  }
  
  .resource-section {
    background-color: var(--background-grey);
    padding: 10px;
    margin-bottom: 40px;
    border-left: 4px solid #000000;
  }
  
  .resource_container ul {
    list-style-type: none;
    padding: 0;
  }
  
  .resource_container ul li {
    margin-bottom: 5px;
    line-height: 1.6;
  }
  
  .resource_container ul li:before {
    content: '• ';
    font-weight: bold;
  }
  
  .resource_container button {
    background-color: var(--primary);
    color: rgb(0, 0, 0);
    font-weight: 600;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  