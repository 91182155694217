.marketplace {
  padding: 20px 0px;
}

.marketplace p {
  margin: 4vh 0px;
}

.marketplace-title {
  background-color: var(--primary);
  padding: 5vh 0px;
}

.marketplace-item {
  background-color: var(--background-grey);
  padding: 40px;
}

.marketplace-item > div + div {
  margin-top: 30px;
}

.marketplace-item + .marketplace-item {
  margin-top: 40px;
}

.padding3 {
  padding: 0px 15vw;
}

.padding4 {
  padding: 0px 12vw;
}

.padding5 {
  padding: 0px 25vw;
}

/* Fonts */

.marketplace .bold {
  font-weight: bold;
}

.font1, .font2, .font3, .font4 {
  font-family: "Neue Haas Grotesk Display Pro";
}

.marketplace .font1 {
  font-size: calc(3vw + 3vh);
}

.marketplace .font2 {
  font-size: calc(2vw + 2vh);
}

.marketplace .font3 {
  font-size: calc(1vw + 1vh);
}

.marketplace .font4 {
  font-size: calc(0.7vw + 0.7vh);
}