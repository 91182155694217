/* Styling for the container of each post recommendation  */

.other-post-card {
    background-color: var(--background-grey);
    margin: 30px 20px;
    width: 30vw;
    border-radius: 20px;
    justify-content: center;
    font-size: small;
    height: auto;
}

.content-gray {
    color: #616265;
    font-weight: 700;

}

img, svg {
    vertical-align: middle
}

*, ::after, ::before {
    box-sizing: border-box
}


.other-post-heading {
    text-decoration: none !important;
    font-size: 1rem;
    font-weight: 650;
}


.postpreview-card a {
    text-decoration: none !important;
    color: black !important;
}


.other-post-image-wrapper {
    display: inline-block;
    overflow: hidden;
    border-radius: 14px;
}


.other-post-preview-image {
    width: 100% !important;
    border-radius: 10px;
    height: 27vh !important;
    object-fit: cover;
    align-self: center;
    justify-self: center;

}


.other-post-card a {
    text-decoration: none !important;
    color: black !important;
}

.h-100 {
    height: 100% !important
}

.flex-column {
    flex-direction: column !important
}

.justify-content-evenly {
    justify-content: space-evenly !important
}

.flex {
    display: flex;
}

.align-content-end {
    align-content: flex-end !important
}

.p-3 {
    padding: 1rem !important
}

.px-1 {
    padding-right: .25rem !important;
    padding-left: .25rem !important
}

.py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important
}


.pt-3 {
    padding-top: 1rem !important
}

.pt-1 {
    padding-top: .25rem !important
}


@media (min-width: 1441px) {
    /* Styling for the container of each post preview */
    .other-post-card {
        font-size: 1.5rem;
        height: auto;
    }

    .other-post-heading {
        margin-top: 2rem;
        font-size: 2rem;
        font-weight: 700;
    }


    .other-post-preview-image {
        height: auto!important;
        max-height: 27vh;
        object-fit: cover;
    }

}

@media (max-width: 768px) {
    .other-post-card {
        width: 95%;
        margin: 10px 0;
        height: 70%;
    }

    .other-post-preview-image {
        height: 100% !important;
        width: 100%;
        object-fit: fill;
    }

    .other-post-image-wrapper {
        padding: 0;
        height: 100%;
        border-radius: 0;

    }

    .other-post-link {
        padding: 1rem
    }

}