/* @tailwind base;
@tailwind components;
@tailwind utilities;

table {
  @apply w-[100%] mb-20;
}

.table-heading {
  @apply text-lg text-center border-b-2 p-1 font-bold;
}

.table-heading:first-of-type {
  @apply text-left;
}

button {
  @apply bg-[#00f6d3] right-0 px-8 py-2 text-lg font-bold w-[30%];
}

button:hover {
  @apply bg-[#000] text-[#00f6d3];
}

.table-data {
  @apply text-center text-lg py-5 font-light;
}

#Product {
  @apply text-left;
}

#Product > h1 {
  @apply font-bold;
}

#Plan > h1 {
  @apply font-bold;
}

.subscription-plans {
  @apply w-[100%] flex flex-col gap-6;
}

.subscription-plan {
  @apply w-[100%] bg-white rounded-xl border-2 px-10 py-5 flex justify-between;
}

.subs-desc {
  @apply w-[50%] flex gap-5 items-start h-[100%];
}

.desc > h1,
.subs-price > h1 {
  @apply text-lg font-bold;
}

.desc > p {
  @apply text-sm font-light;
}

.desc > p:nth-child(3) {
  @apply mt-2;
}

.quotes-filters {
  @apply mb-8;
}

.quotes-filter {
  @apply py-[5px] px-[15px] text-xl font-bold opacity-60 text-left;
}

.quotes-filter.active {
  @apply opacity-100 border-b-[1px] border-black;
}

.quotes-filter:hover {
  @apply cursor-pointer opacity-100;
}

.billing-profiles {
  @apply w-[100%] flex flex-col gap-5;
}

.billing-profile {
  @apply bg-white w-[100%] p-[20px] rounded-lg border-2 flex flex-row justify-between;
}

.profile-subs {
  @apply flex;
}

.profile-company {
  @apply font-light;
}

.profile-subs > h2,
.manage-profile {
  @apply px-5;
}

.profile-subs > h2:first-of-type {
  @apply border-r-2 font-light;
}

.manage-profile {
  @apply font-bold;
}

.manage-profile:hover {
  @apply opacity-60;
}

.card-payment-container,
.invoice-payment-container {
  @apply mb-5 max-w-[980px];
}

.payment-method,
.invoice-payment {
  @apply w-[100%] py-5 px-5 border-2 rounded-lg bg-white;
}

.table-heading-permissions {
  @apply text-lg text-center border-b-2 p-1 font-extralight;
}

.table-heading-permissions:first-of-type {
  @apply text-left;
}

.input-style {
  @apply border-2 w-[100%] text-lg p-1;
}

label {
  @apply text-lg;
}

button.inverted {
  @apply bg-transparent;
}
button.inverted:hover {
  @apply bg-transparent text-black;
}

.payment-select-card {
  @apply border-[1px] rounded-lg border-black w-[50%] flex gap-1 justify-center items-center p-2 cursor-pointer;
}

.payment-select-card.active {
  @apply border-[#00f6d3] bg-[#00f6d3];
} */

table {
  width: 100%;
  margin-bottom: 20px;
}

.table-heading {
  font-size: 1rem;
  text-align: center;
  border-bottom: 2px solid #e5e7eb;
  padding: 0.25rem;
  font-weight: bold;
}

.table-heading:first-of-type {
  text-align: left;
}

button {
  background-color: #00f6d3;
  right: 0;
  padding: 0.5rem 2rem;
  font-size: 1.125rem;
  font-weight: bold;
  width: 30%;
}

button:hover {
  background-color: #000;
  color: #00f6d3;
}

.table-data {
  text-align: center;
  font-size: 1.125rem;
  padding: 1.25rem 0;
  font-weight: 300;
}

#Product {
  text-align: left;
}

#Product > h1,
#Plan > h1 {
  font-weight: bold;
}

.subscription-plans {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.subscription-plan {
  width: 100%;
  background-color: white;
  border-radius: 1rem;
  border: 2px solid #e5e7eb;
  padding: 1rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subs-desc {
  width: 50%;
  display: flex;
  gap: 1.25rem;
  align-items: center;
  height: 100%;
  color: black;
}

.desc > h1,
.subs-price > h1 {
  font-size: 1.125rem;
  font-weight: bold;
}

.desc > p {
  font-size: 0.875rem;
  font-weight: 300;
}

.desc > p:nth-child(3) {
  margin-top: 0.5rem;
}

.quotes-filters {
  margin-bottom: 2rem;
}

.quotes-filter {
  padding: 0.3125rem 0.9375rem;
  font-size: 1.25rem;
  font-weight: bold;
  opacity: 0.6;
  text-align: left;
}

.quotes-filter.active {
  opacity: 1;
  border-bottom: 1px solid black;
}

.quotes-filter:hover {
  cursor: pointer;
  opacity: 1;
}

.billing-profiles {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.billing-profile {
  background-color: white;
  width: 100%;
  padding: 1.25rem;
  border-radius: 0.5rem;
  border: 2px solid #e5e7eb;
  display: flex;
  justify-content: space-between;
}

.profile-subs {
  display: flex;
}

.profile-company {
  font-weight: 300;
}

.profile-subs > h2,
.manage-profile {
  padding: 0 1.25rem;
}

.profile-subs > h2:first-of-type {
  border-right: 2px solid #e5e7eb;
  font-weight: 300;
}

.manage-profile {
  font-weight: bold;
}

.manage-profile:hover {
  opacity: 0.6;
}

.card-payment-container,
.invoice-payment-container {
  margin-bottom: 1.25rem;
  max-width: 980px;
}

.payment-method,
.invoice-payment {
  width: 100%;
  padding: 1.25rem;
  border: 2px solid #e5e7eb;
  border-radius: 0.5rem;
  background-color: white;
}

.table-heading-permissions {
  font-size: 1.125rem;
  text-align: center;
  border-bottom: 2px solid #e5e7eb;
  padding: 0.25rem;
  font-weight: 200;
}

.table-heading-permissions:first-of-type {
  text-align: left;
}

.input-style {
  border: 2px solid;
  width: 100%;
  font-size: 1.125rem;
  padding: 0.25rem;
}

label {
  font-size: 1.125rem;
}

button.inverted {
  background-color: transparent;
}

button.inverted:hover {
  background-color: transparent;
  color: black;
}

.payment-select-card {
  border: 1px solid black;
  border-radius: 0.5rem;
  width: 50%;
  display: flex;
  gap: 0.25rem;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  cursor: pointer;
}

.payment-select-card.active {
  border-color: #00f6d3;
  background-color: #00f6d3;
}
