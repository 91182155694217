.career_container {
  margin: auto; 
  padding: 80px 10vw;
  line-height: 1.5;
}

.subtext {
  font-family: Inter;
  font-size: 32px;
}

.career_container h1,
.career_container h2 {
  margin-bottom: 10px;
}

.career_container h3 {
  color: #4a4a4a;
  margin-top: 15px;
  margin-bottom: 5px;
}

.career_container span {
  color: black;
}

.career_container>.subheader_text {
  padding: 20px 0px;
}

.values_block {
  padding-bottom: 40px;
}

.mobile-layout {
  display: none;
}

.desktop-layout {
  display: block;
}

/* .core-value_card {
  display: none;
} */


/* Mobile View */






.careers_two-item-block {
  display: flex;
  justify-content: center;
  column-gap: 4%;
}

.careers_two-item-block>div,
.careers_left-block {
  width: 48%;
}

.careers_left-block {
  padding-bottom: 40px;
}

.header_img-block {
  margin: 0px 2%;
}

.header_img-block img {
  width: 92%;
  object-fit: cover;
}





/* Desktop Layout Core Value */
.desktop-layout {
  display: flex;
  justify-content: space-between;
  gap: 20px; 
  padding: 20px;
}

.column {
  flex: 1; 
  display: flex;
  flex-direction: column;
  gap: 20px; 
}


.desktop_core_item {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  padding-bottom: 20px;
}

.desktop_core_value {
  background-color: var(--primary); 
  color: black; 
  height: 50px;
  min-width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px; 
  font-weight: 700;
  box-sizing: border-box; 
}

.desktop_core_content {

  display: block; 
  margin: 0;
  font-size: 24px;
  line-height: 1.4;
}

.desktop_core_title {
  display: inline-block;
  font-size: 30px;
  margin-right: 5px;
  white-space: nowrap; 

}

.desktop_core_description {
  display: inline; 
  white-space: normal;
}

  

.competency-model .careers_two-item-block {
  padding-bottom: 20px;
  align-items: center;
}

.competency-model .medium {
  padding-bottom: 20px;
  font-weight: bold;
}

.competency-model img {
  width: 100%;
  border-radius: 20px;
  max-height: 540px;
  object-fit: cover;
}

.performance-expectations {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-bottom: 80px;
  row-gap: 80px;
  column-gap: 60px;
  text-align: center;
  font-weight: bold;
}



.icon-block {
  width: 100px;
  height: 100px;
  margin: 0px auto 20px auto;
}

.icon-block img {
  width: 100%;
}

/* @media (max-width: 600px) {
  .competency-model img,
  .icon-block img {
    width: 100%;
    height: auto;
  }
} */

/* Worklife section */


.worklife-container {
  background-color: var(--background-grey);
  padding: 20px 40px;
  margin-bottom: 40px;
}

.worklife-options {
  display: flex;
  justify-content: space-between;
}

.worklife_sort-by {
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.worklife_sort-by_options {
  height: 100%;
  border: none;
  background-color: var(--background-grey);
  cursor: pointer;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.jobs {
  padding: 20px 0px;
}

.job-posting {
  text-decoration-line: none;
  font-size: 30px;
  color: black;
  font-weight: bold;
  margin-bottom: 200px;
  animation: fadeInAnimation ease 0.5s;
}

.job-posting>div {
  background-color: var(--background-grey);
  padding: 20px 10px;
  border-radius: 5px;
}

.job-posting>div:hover {
  filter: brightness(80%);
  transition: 0.3s;
}

.job-posting>div:active {
  filter: brightness(120%);
}

.careers_box {
  background-color: var(--primary);
  padding: 100px 0px;
  margin: 40px auto 0px auto;
  width: 90%;
  align-self: center;
}

.careers_box>div {
  margin: 0px 5vw;
  text-align: start;
}

/* Careers Description page */

.jobs_css {
  margin-bottom: 0px;
}

.apply_btn {
  width: 300px;
}

.apply_btn:hover {
  color: white !important;

}

@media (max-width: 768px) {
  .careers_two-item-block {
    flex-direction: column;
  }

  .careers_two-item-block>div,
  .careers_left-block {
    width: 100%;
  }

  
} 

@media (min-width: 769px) {
  /* Hide the 'Read More' link on larger screens */
  .read-more-link,
  .read-less-link {
    display: none;
  }

  /* Ensure that all content is shown on larger screens */
  .careers_body .hidden {
    display: block;
  }
}


@media (min-width: 769px) {
  .mobile-sort-container {
    display: none;
  }
}




@media (max-width: 768px) {
  .career_container {
    padding: 10px 20px;
  }

  

  .career_container h1 {
    font-weight: bold;
    font-size: 29px;
    letter-spacing: 0%;
  }

  .header_img-block {
    padding-bottom: 10px;
  }

  .line_adjust {
    display: none;
  }

  .subtext {
    font-size: 18px;
    font-weight: bold;
    line-height: 120%;
    padding-bottom: 10px;
  }

  .careers_body {
    font-weight: medium;
    font-size: 16px;
    line-height: 140%;
    position: relative;
  }

  .careers_body .hidden {
    display: none;
  }

  .read-more-link {
    font-weight: bold;
    text-decoration: underline;
    color: var(--primary); /* Adjust color as needed */
    cursor: pointer;
  }

  .read-less-link {
    font-weight: bold;
    text-decoration: underline;
    color: var(--primary); /* Adjust color as needed */
    cursor: pointer;
  }

  .flowValues {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 16px;
  }

  .desktop-layout {
    display: none;
  }

  .mobile-layout {
    display: block;
  }

  .flowValues .colon {
    display: none; 
  }

  .flowValues span {
    display: block;
    margin-bottom: 5px; 
  }

  .values_block {
    padding-bottom: 0px;
  }

  /* .core_val_block {
    padding-bottom: 40px !important;
    align-items: center;
    margin-left: 10px;
  } */


  .mobile_value_number {
    position: relative;
    border-radius: 20px;
  }

  .mobile_tick {
    background-color: var(--primary); 
    color: black; 
    height: 50px;
    width: 50px; 
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px; 
    font-weight: normal;
    box-sizing: border-box; 
    border-radius: 40%;
  }
  
  .mobile_value_number::before {
    content: '✔'; /* Tick sign */
    font-size: 18px;
    color: black; /* Adjust to match the size */
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .mobile_value_number span, 
  .mobile_value_number {
    color: transparent; /* Hide the number without hiding the background */
  }
  
  .flow-val {
    font-size: 24px !important;
    font-weight: bold;
    color: white !important;
    font-family: inter !important;
  }
  
  .core-value_card {
    display: flex;
    flex-direction: column;
    align-items: left;
    background-color: black;
    color: white;
    height: 460px;
    width: 100%;
    border-radius: 20px;
    padding-top: 35px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .mobile_tick {
    margin-bottom: 5px;
  }

  /* carousel started  */
  /* .carousel-container {
    overflow: hidden;
    width: 100%;
    position: relative;
  }

  .carousel-track {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch; /* For smooth scrolling on iOS */

    /* .carousel_container {
      overflow: hidden;
      width: 100%;
      padding: 0 10px;
    } */

    .carousel_container {
      margin-left: 0; /* Remove negative margin */
      width: 100%; /* Full width */
      padding-left: 20px; /* Add left padding for initial space */
      padding-right: 0; /* Remove right padding */
      overflow: hidden; /* Hide overflow */
    }
    
  
    .carousel_wrapper {
      display: flex;
      overflow-x: auto;
      scroll-snap-type: x mandatory;
      -webkit-overflow-scrolling: touch;
      scrollbar-width: none; 
      margin-right: -20px;
      padding-right: 20px;
      width: calc(100% + 20px);
      /* padding: 0 10px; */
    }

    .carousel_wrapper::-webkit-scrollbar {
      display: none; /* Chrome, Safari and Opera */
    }
    
  
    .carousel_card {
      flex: 0 0 85%; 
      scroll-snap-align: start; 
      width: 85%;
      padding-right: 15px;
      margin-right: 0px;
    }

    /* competency-model */
    .mobile_model_container {
      padding-bottom: 40px !important;
    }

    .mobile_medium {
      font-weight: bold;
      font-size: 16px !important;
    }

    .mobile_competency_model {
      display: flex;
      flex-direction: column;
    }


    .competency_mobile_even {
      font-size: 24px !important;
      order: 1;
    }

    .skills_image_mobile {
      order: 2;
    }

    .skills_des_mobile {
      order: 3;
      padding-bottom: 10px;
    }

    .competency_mobile_skills {
      font-size: 24px !important;
    }

    .skills_des_mobile {
      font-size: 16px ;
    }
    
    /* Performance Expectations */
    .mobile_performance_block {
      font-size: 16px;
    }

    .mobile_icon_image {
      border-radius: 25px;
    }

    /* Rewards and Recognition */
    .mobile_rewards_title {
      font-size: 24px !important;
      font-weight: bold;
    }

    .mobile_rewards_content {
      font-size: 16px;
    }

    /* Worklife */
   

    /* .mobile_worklife_container {
      /* margin: 0;
      padding-left: 0px !important;
      padding-right: 0px !important; */
      /* margin: 0 -10px;
      width: calc(100% + 20px);
      padding: 10px;
      overflow: hidden;
    } */ 

    .job_label_mobile {
      font-size: 24px;
    }

    .MuiCheckbox-root {
      border-radius: 20px; /* Apply border radius to checkboxes */
      padding: 0; /* Remove default padding if needed */
    }
    
    .MuiSvgIcon-root {
      border-radius: 20px; /* Apply border radius to the checkbox icon */
      /* Add any additional styles if needed */
    }

    .mobile_options {
      display: none !important;
    }

    .job-posting > div {
      margin: 0 -10px;
      padding: 15px 10px; /* Adjust vertical padding */
      width: calc(100% + 20px);
    }
  
    .job-posting {
      margin-bottom: 20px; /* Reduce space below job postings */
    }

    .hide-on-mobile {
      display: none;
    }

    .mobile_worklife_title {
      background-color: #F3F3F3;
      padding-left: 20px !important;
      padding-bottom: 10px !important;
    }

    .mobile_changed_text {
      display: none;
    }
    .custom-label{
      font-size: 16px !important;
    }

    .mobile_job_results {
      font-weight: normal  !important;
      font-size: 21px !important;
    }

    .mobile_jobs {
      font-size: 21px !important;
      font-weight: bolder;
    }

    .mobile_change_weight {
      /* border-width:medium; */
      border-color: darkgray;
    }
    /* career box */
    .careers_box {
      display: none;
    }

    .mobile_workflow {
      padding-bottom: 20px !important;
    }

    .mobile-sort-container {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      background-color: #F3F3F3;
      padding: 10px;
      /* border-radius: 5px; */
    }
  
    .mobile-sort-label {
      font-size: 16px;
      font-weight: bold;
      margin-right: 10px;
    }
  
    .mobile-sort-select {
      font-size: 16px;
      min-width: 100px;
    }

    

}

@media (max-width: 768px) {
  .performance-expectations {
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    margin-left: 0px;
  }
}