.read-more-card {
  border: 1px solid #e0e0e0;
  border-radius: 15px;
  padding: 20px;
  text-align: center;
  width: 100%;
  max-width: 350px; /* Limit the max width for better appearance */
  margin: 10px auto;
  background-color: #f7f7f7;  /* Ensure the background is white */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center all content horizontally */
  box-sizing: border-box; /* Include padding and border in element's total width */
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px; /* Space below the image */
}

.circular-image {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  object-fit: cover;
  border: 2px solid #ccc;
}

.custom-title {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0;
  color: #000000;
}

.custom-text {
  font-size: 14px;
  line-height: 1.5;
  color: #000000;
  text-align: left; /* Align the paragraph text to the left */
  margin: 10px 0;
}

.read-more-button {
  color: #000000;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none; /* No underline */
  margin-top: 10px;
  display: block;
  text-align: right;
}

.read-more-button:hover {
  text-decoration: underline; /* Add underline on hover */
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .read-more-card {
    max-width: 90%; /* Take most of the screen width */
    padding: 15px;
  }
}
