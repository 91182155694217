/* Container for the application form */
.application-form-container {
  width: 100vw;
  margin: 0 auto;
  padding: 20px;
  background-color: var(
    --background-grey
  ); /* Ensure consistent background color */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Heading styles */
.application-form-container h2 {
  text-align: center;
  font-family: "Neue Haas Grotesk Display Pro", sans-serif; /* Consistent font */
  font-size: 30px; /* Updated to match app.css */
  font-weight: 700; /* Bold heading */
  color: black; /* Ensure text color consistency */
  margin-bottom: 20px;
}

.contact-policy {
  margin: 20px 0;
  display: flex;
  align-items: center;
}

.contact-policy-checkbox {
  margin-right: 10px;
}

.contact-policy-label {
  font-size: 14px;
  font-family: "Inter", sans-serif; /* Match global font */
}

.contact-policy-link {
  color: var(--primary); /* Consistent link color */
  text-decoration: none;
  font-weight: bold;
}

.contact-policy-link:hover {
  font-weight: bold;
  text-decoration: underline;
}

.error-message {
  color: var(--warning); /* Use global warning color */
  font-size: 14px;
  margin-top: 5px;
}

/* Spacing between form fields */
.application-form-container .MuiTextField-root,
.application-form-container input[type="file"],
.application-form-container .contact-policy,
.application-form-container button {
  margin-bottom: 15px; /* Consistent spacing */
}

/* Styling for the file input field */
.application-form-container input[type="file"] {
  width: 100%; /* Full width to match the other input fields */
  padding: 5px;
  font-size: 14px; /* Adjust font size */
  border: 1px solid var(--border-color); /* Border color */
  border-radius: 4px; /* Rounded corners */
}
/* Adjust spacing for consistency */
.application-form-container input[type="file"] {
  margin-bottom: 15px; /* Consistent spacing */
}

/* Button styling */
.application-form-container button {
  width: 15vw; /* Adjusted width */
  padding: 10px 20px;
  background-color: var(--primary); /* Use primary color */
  color: black !important;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.1rem; /* Consistent font size */
  font-weight: bold; /* Bold text */
  transition: background-color 0.3s, box-shadow 0.3s;
}

/* Hover state for buttons */
.application-form-container button:hover {
  background-color: black !important;
  color: var(--background-white) !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-color: white !important;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  color: white;
}

.loader-overlay p {
  margin-top: 1rem;
  font-size: 1.2rem;
}
