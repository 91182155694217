.application-list-container {
  position: relative;
  width: 100%; /* Full width to adapt to different screen sizes */
  background: #f3f3f3;
  padding: 20px;
  margin: 0 auto;
  
}

.application-list-heading {
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif; /* Consistent font family */
  font-size: 2.5rem; /* Larger font size for emphasis */
  color: black;
  text-align: center; /* Center-align heading */
  margin-bottom: 30px; /* Adds space below the heading */
}

.application-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px; /* Adjust spacing between rows */
  background: white; /* White background for the table */

}

.application-table th,
.application-table td {
  padding: 12px 20px; /* Adjust padding for better spacing */
  text-align: center; /* Center-align text in cells */
  border-bottom: 1px solid #ddd;
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
}

.application-table th {
  background-color: #01f6d3;
  color: black;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
  border-top: 1px solid #ddd;
}




.application-table td {
  font-size: 15px;
  color: black;
  font-weight: 500;
}

.application-table .status {
  font-weight: bold;
}

.resume-download {
  padding: 5px 10px;
  background: #01f6d3;
  color: black;
  text-decoration: none;
  border: 1px solid #01f6d3;
  font-weight: bold;
  width: fit-content;

}

.resume-download:hover {
    background: #01f6d3;
    border-color: #01f6d3;
}

.applicant-count {
  font-size: 1.1rem; /* Consistent font size */
  color: black;
  margin-bottom: 15px;
}

/* Style for buttons in the action column */
.application-table .delete-buttons {
  gap: 10px;
  align-items: center;
}

.application-table .delete-buttons button {
  width: auto;
  border: none;
  border-radius: 4px;
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  background: #FF4C4C;
  color: white;
}

.application-table .delete-buttons button:hover {
  background: #D43F3F;
}