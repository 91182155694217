/* JobDetail.css */

.job-detail-container {
  position: relative;
  width: 100vw;
  background-color: var(--background-grey);
}

.job-detail-content {
  position: absolute;
  width: 1277px;
  height: 1139px;
  left: 51px;
  top: 127px;
}

.job-title {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  color: #191919;
  margin-bottom: 20px;
}

.job-info {
  margin-bottom: 20px;
}

.info-heading {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #191919;
  margin-top: 30px;
  margin-bottom: 10px;
}

.info-text {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #1C1C1C;
}
.info-text strong,
.info-text span {
  color: #000000; /* Black color for <strong> and <span> tags */
}

.job-info ul {
  list-style-type: disc;
  margin-left: 20px;
}

.job-info ol {
  list-style-type: decimal;
  margin-left: 20px;
}

.apply-btn-container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.apply-btn-container Button {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #FFFFFF;

  padding: 15px 50px;
  border: none;
}

/* .apply-btn-container Button:hover {
  background: #00CCAA;
} */