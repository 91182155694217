.sales-support {
  padding: 80px 15vw;
}

.sales-support .large.bold {
  padding-bottom: 20px;
}

.sales-support>.sales-support_two-item-block {
  padding-top: 80px;
}

.sales-support_two-item-block {
  display: flex;
  column-gap: 4%;
}

.sales-support_two-item-block>*,
.sales-support_right-block {
  width: 48%;
}

.sales-support_right-block {
  padding-left: 52%;
  margin-top: 30px;
}

.sales-support_right-block>.MuiButton-root {
  width: 300px;
  border-radius: 25px;
}

.sales-support-image-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 200px;
}

.sales-support-image {
  height: 50%;
  max-width: 80%;
}

/* Achievements */

.achievements-header {
  padding-bottom: 20px;
  line-height: 1.5em;
}

.achievements {
  position: relative;
  margin-left: 0;
  margin-bottom: 0;
}

.achievement {
  display: flex;
  align-items: center;
  column-gap: 8%;
}

.achievement+.achievement {
  padding-top: 25px;
}

.achievement-marker {
  padding-left: 1.5em;
}

.achievement-marker>li::marker {
  content: ">   ";
  color: var(--primary);
}

.achievement-text>div {
  font-size: 20px;
}

.achievement-text>div+div {
  margin-top: 15px;
}

.achievement-image {
  height: calc(2em + 45px);
}

/* Sales Support Form */

.sales-support-form_container {
  display: flex;
  flex-direction: column;
}

.sales-support-form {
  margin-top: auto;
}

.sales-support-form>input {
  width: calc(100% - 8px);
}

.sales-support_input-container>.error-validation {
  position: absolute;
  margin-left: 5px;
  color: red;
  font-size: 14px;
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
}

.sales-support_input-container>.error-validation::first-letter {
  text-transform: uppercase;
}

.sales-support_input {
  background: none;
  border: 0;
  outline: 0;
  color: black;
  border-bottom: 1px solid black;
  font-size: 20px;
  padding-bottom: 10px;
  padding-left: 5px;
  width: 100%;
}

.sales-support_input::placeholder {
  color: black;
}

.sales-support-form>*+* {
  padding-top: 25px;
}

.privacy-policy {
  font-size: 20px;
  margin-top: 40px;
}

.privacy-policy a {
  font-weight: bold;
  color: black;
}

/* Laptop */

@media (max-width: 1920px) {
  .sales-support {
    padding: 60px 10vw;
  }

  .achievement {
    display: flex;
    align-items: center;
    column-gap: 4%;
  }
}

/* Mobile/Tablet */

@media (max-width: 1000px) {
  .sales-support {
    padding: 20px 5vw;
  }

  .sales-support_two-item-block {
    display: block;
    column-gap: 0;
  }

  .sales-support>.sales-support_two-item-block {
    padding-top: 40px;
  }

  .sales-support_two-item-block>* {
    width: 100%;
  }

  .sales-support_right-block {
    padding-left: 0;
  }

  .achievements {
    padding-bottom: 40px;
  }

  .achievement {
    display: block;
  }

  .achievement+.achievement {
    padding-top: 0px;
  }

  .achievement-image {
    padding-left: 1.5em;
  }

  .sales-support_input-container+.sales-support_input-container {
    padding-top: 25px;
  }

  .sales-support-image-container {
    padding-top: 60px;
  }

  .sales-support-image {
    max-width: 100%;
  }
}