/* privacy_container.css */
.privacy {
  color: black;
  line-height: 1.6;
}

.privacy_container {
  margin: 100px 10vw;
}

.privacy_container h1,
.privacy_container h2,
.privacy_container h3,
.privacy_container p,
.privacy_container ul {
  margin-bottom: 15px;
}

.privacy_container p,
.privacy_container ul,
.privacy_container li {
  font-size: 16px;
}

.privacy_container h2 {
  font-size: 24px;
}

.privacy_container ul {
  list-style: none;
  padding-left: 0;
}

.privacy_container li {
  position: relative;
  padding-left: 20px;
}

.privacy_container li::before {
  content: '•';
  color: var(--primary);
  position: absolute;
  left: 0;
  top: 0;
}
