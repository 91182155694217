
.hr-login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--background-grey);
}

.hr-main {
  text-align: center;
  background-color: var(--background-grey);
  padding: 1rem;
  width: 400px;
}

.hr-title {
  font-size: 2rem;
  color: #ff0000;
  margin-bottom: 1.5rem;
  font-weight: bold;
}

.hr-login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hr-login-form label {
  width: 100%;
  text-align: left;
  margin-bottom: 5px;
  font-size: 1rem;
  color: #636262;
  font-weight: normal;
}

.hr-input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  box-sizing: border-box; 
  height: 40px;
  background-color: #ffffff !important;
}



.primary-btn {
  width: 100%;
  padding: 10px 20px;
  background-color: var(--primary);
  color: black;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  font-size: 1.1rem;
  font-weight: bold;
  transition: background-color 0.3s, box-shadow 0.3s, color 0.3s;
}

.primary-btn:hover {
  background-color: black;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}