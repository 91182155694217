/* terms_container.css */
.terms {
    color: black;
    line-height: 1.6;
  }

.terms_container {
    margin: 100px 10vw;
  }

.terms_container h1,
.terms_container h2,
.terms_container h3,
.terms_container p,
.terms_container ul {
    margin-bottom: 15px;
}

.terms_container p,
.terms_container ul,
.terms_container li {
  font-size: 16px;
}

.terms_container h2 {
  font-size: 24px;
}

.terms_container ol {
    list-style-type: decimal;
    padding-left: 20px;
}

.terms_container ul {
    list-style: none;
    padding-left: 0;
}

.terms_container ul li {
    position: relative;
    padding-left: 20px;
}
.terms_container ol li {
    position: relative;
}

.terms_container ul li::before {
    content: '•';
    color: var(--primary);
    position: absolute;
    left: 0;
    top: 0;
}

ol > ol > li {
    list-style: none;
}

