/* @tailwind base;
@tailwind components;
@tailwind utilities;

.navigation-heading {
  @apply font-bold opacity-60;
}

.navigation-submain {
  @apply ml-5 mt-2 font-light;
}

.navigation-sub {
  @apply ml-10 font-light;
}

.navigation-sidebar {
  @apply flex flex-col gap-5;
}

.navigation-subheadings {
  @apply flex flex-col gap-3;
}

.navigation-submain,
.navigation-sub {
  @apply text-lg;
}

.navigation-heading.active {
  @apply border-[#00f6d3]  border-b-4 pb-2 opacity-100;
}

.active-subheading {
  @apply font-bold;
}

button {
  @apply bg-[#00f6d3] right-0 px-8 py-2 text-lg font-bold w-[30%];
}

button:hover {
  @apply bg-[#000] text-[#00f6d3];
} */

.navigation-heading {
  font-weight: bold;
  opacity: 0.6;
  margin: 0;
}

.navigation-submain {
  margin-left: 0.5rem;
  margin-top: 0.2rem;
  font-weight: 300;
}

.navigation-sub {
  margin-left: 2rem;
  font-weight: 300;
}

.navigation-sidebar {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.navigation-subheadings {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.navigation-submain,
.navigation-sub {
  font-size: 1.125rem;
}

.navigation-heading.active {
  border-bottom: 4px solid #00f6d3;
  opacity: 1;
}

.active-subheading {
  font-weight: bold;
}

button {
  background-color: #00f6d3;
  right: 0;
  padding: 0.5rem 2rem;
  font-size: 1.125rem;
  font-weight: bold;
  width: 30%;
}

button:hover {
  background-color: #000;
  color: #00f6d3;
}
