.grid-container {
    width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* Two columns with equal width */
    gap: 80px;
    justify-items: center;
}

.purchase-tier-section {
    flex-wrap: nowrap !important;
    padding: 10px;
    font-size: 16px;
}

.error {
    margin-top: 0px;
    color: red;
}

/* Added for from validation error messgages */
.error-validation {
    color: red;
    font-size: 12px;
    font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
    /* Use the same font as the font4 class */
}


.button {
    margin: 5px;
    color: black;
    display: inline-block;
    padding: 5px 2vw;
    background-color: var(--primary);
    text-decoration: none;
    border: 0;
    border-radius: 10px;
    cursor: pointer;
}

.navigationButton p {
    margin: 0 0;
    padding: .8vw 5px;
}

.blackBkg {
    background-color: black !important;
}

.smallIcon {
    width: 20px;
    height: 20px;
}

.medIcon {
    width: 30px;
    height: 30px;
}

.sectionContainer {
    width: 45%;
    padding: 0px 2vw;
}

.leftPadding {
    padding-left: 10px;
}

.smallBottomPadding {
    padding-bottom: 5px;
}

.largeBottomPadding {
    padding-bottom: 20vh;
}

.maxWidth {
    width: 100%;
}

.formField {
    padding: 8px 0px;
}

.formField+.formField {
    margin-top: 8px;
}

.formTextBox,
.formTextArea {
    border: 0;
    border-radius: 5px;
    background-color: var(--background-white);
    padding: 5px 10px;
    font-family: "Neue Haas Grotesk Display Pro";
    font-size: calc(0.45vw + 0.6vh);
}

.formTextBox {
    height: 30px;
}

.formTextBox::-webkit-outer-spin-button,
.formTextBox::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.formTextBox[type=number] {
    appearance: textfield;
}

.formTextArea {
    resize: none;
    height: 150px;
    padding-top: 10px;
}

.formButton {
    height: 3vh;
    min-height: 35px;
    width: 100%;
}

.noMargin {
    margin: 0;
}

.grey {
    background-color: var(--background-grey);
}

/* Contact Flow */

.customer-service_container {
    padding: 80px 15vw;
}

.customer-service_container .MuiButton-root {
    width: 100%;
}

.float-bottom {
    display: flex;
    flex-direction: column;
}

.float-bottom>* {
    margin-top: auto;
}

.customer-service_two-item-block {
    display: flex;
    column-gap: 12%;
}

.header_text+.customer-service_two-item-block {
    margin-top: 40px;
}

.customer-service_two-item-block+.customer-service_two-item-block {
    margin-top: 100px;
}

.customer-service_two-item-block>* {
    width: 44%;
}

.info-card>*+*:not(h2),
.info-card>img+h2 {
    margin-top: 20px;
}

.billing-btn {
    background-color: var(--accent) !important;
    color: white !important;
    font-weight: 600 !important;
    font-size: 20px !important;
    transition: all 0.3s;
    border: 1px solid !important;
    border-color: transparent !important;
    text-transform: none !important;
}

.billing-btn:hover {
    background-color: black !important;
    color: var(--accent) !important;
    border-color: black !important;
}

.password-btn {
    background-color: black !important;
    color: white !important;
    font-weight: 600 !important;
    font-size: 20px !important;
    transition: all 0.3s;
    border: 1px solid !important;
    border-color: transparent !important;
    text-transform: none !important;
}

.password-btn:hover {
    background-color: white !important;
    color: black !important;
    border-color: black !important;
}

/* Mobile/Tablet */

@media (max-width: 1000px) {
    .customer-service_container {
        padding: 40px 5vw;
        font-size: 16px;
    }

    .customer-service_container .header_text {
        font-size: 40px;
    }

    .customer-service_container .bold {
        font-size: 20px;
    }

    .customer-service_two-item-block {
        display: block;
        column-gap: 0;
    }

    .customer-service_two-item-block>* {
        width: 100%;
    }

    .customer-service_two-item-block>*+*,
    .customer-service_two-item-block+.customer-service_two-item-block {
        margin-top: 60px;
    }

    .customer-service_container .MuiButton-root {
        width: 100% !important;
        font-size: 16px !important;
    }

    .mobile-hidden {
        display: none;
    }
}

/* Animations */

.hide3 {
    opacity: 0;
    transition: all 1s;
    transition-timing-function: ease;
}
.show3 {
    opacity: 1;
}