.about-us {
  padding: 40px 0px;
}

/* Rule to temporarily restore defaults to About Us page */
.about-us h1              { font-size: 2em; margin: .67em 0; font-weight: bold; }
.about-us h2              { font-size: 1.5em; margin: .75em 0; font-weight: bold; }
.about-us h3              { font-size: 1.17em; margin: .83em 0; font-weight: bold; }
.about-us h4              { font-weight: bold; }
.about-us h4, .about-us p,
.about-us blockquote, .about-us ul,
.about-us fieldset, .about-us form,
.about-us ol, .about-us dl, .about-us dir,
.about-us menu            { margin: 1.12em 0 }
.about-us h5              { font-size: .83em; margin: 1.5em 0; font-weight: bold; }
.about-us h6              { font-size: .75em; margin: 1.67em 0; font-weight: bold; }

.intro{
  display: flex;
  flex-direction: column;
  align-items: center;
}



.intro h1 {
  font-size: 60px;
  align-self: start;
}

.intro-text {
  align-self: start;
}

.about-us li, .about-us div {
  line-height: 30px;
}

.med-line-height {
  line-height: 50px;
  font-size: 30px;
}

.illustration {
  max-width: 45%;
  height: auto;
  margin-top: -4%;
  margin-bottom: 20px;
}

.img2 {
  width: 500px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 20px;
}

.about-us_section-white {
  background-color: var(--background-white);
}

.about-us_section-grey {
  background-color: var(--background-grey);
}

.center, .center2 {
  display: flex;
  justify-content: center;
  text-align: center;
}

.center {
  align-items: center;
}

.center3 {
  display: flex;
  justify-content: center;
}

.about-us_title {
  background-color: var(--primary);
  padding: 100px 0px;
}

.about-us_subtitle {
  padding: 20px 0px;
}

.overview, .reputation{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 100px;
}

.reputation{
  padding: 20px 10vw;
}

.overview > div, .reputation > div {
  flex: 0.5;
}

.img1, .img3{
  width: 100%;
  height: auto;
}

.about-text{
  display: flex;
  column-gap: 80px;
  padding: 20px 0px;
}

.about-text div {
  width: 50%;
}

.core-competency {
  width: 400px;
}

.core-competency-text{
  text-align: left;
}

.core-competency_square {
  width: 80px;
  height: 80px;
  background-color: var(--primary);
}

.overview2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 60px 0;
  gap: 100px;
}

.overview2 div {
  width: 50%;
}

.block-container {
  padding: 20px 10vw;
}

.two-item-block {
  display: flex;
  column-gap: 100px;
  margin: 20px 0px;
}

.two-item-block div {
  width: 50%;
}

.two-item-block div h1, .two-item-block div h2 {
  word-break: keep-all;
}

.two-item-block div h2 span {
  color: var(--primary);
}

.overview_ul {
  padding: 20px;
}

.padding1 {
  padding: 20px 10vw;
}

.pledge{
  padding: 0px 10vw;
}

.pledge li{
  padding-bottom: 40px;
}

/* .padding2 {
  padding: 20px 20vw;
} */

/* Mobile Styles */
/* Mobile Styles */
/* Mobile Styles */
/* Mobile Styles */

.collapsible-content ul {
  list-style-type: none; /* Removes default bullets */
  padding-left: 0; /* Removes default padding */
}

.collapsible-content ul li {
  position: relative;
  padding-left: 20px; /* Adds space for the custom bullet */
}

.collapsible-content ul li::before {
  content: '•'; /* Custom bullet point */
  color: black; /* Bullet color */
  font-weight: bold; /* Bullet weight */
  position: absolute;
  left: 0;
  top: 0;
}


@media (max-width: 768px) {




  .about-us h1 {
    font-size: 1.5em; /* Adjust the font size for mobile */
    margin: 0.5em 0; /* Adjust the margin for mobile */
    font-weight: bold;
  }
  .block-container h1 {
    font-size: 1.2em !important; /* Adjust font size to fit on one line */
    white-space: nowrap !important; /* Prevent text from wrapping to the next line */
    width: 100%; /* Use full width of the container */
    margin: 0 auto; /* Center the text */
    text-align: left; /* Center-align text */
  }

  .competencies h1 {
    font-size: 1.2em !important; /* Adjust font size to fit on one line */
    white-space: nowrap !important; /* Prevent text from wrapping to the next line */
    overflow: hidden; /* Hide any overflow */
    text-overflow: ellipsis; /* Add ellipsis (...) if the text is too long */
    width: 100%; /* Use full width */
    margin: 0 auto; /* Center the text */
  }

  .illustration {
    width: 100% !important; /* Force the image to take full width */
    height: 100% !important; /* Maintain aspect ratio */
    display: block; /* Ensure it is a block element */
    margin: 0 auto; /* Center the image */
  }

  /* Style the h2 intro text to be more visually appealing */
  .intro-text.subheader_text {
    font-size: 0.8em !important; /* Adjust font size for optimal line count */
    line-height: 1.2 !important; /* Slightly increase line height for better readability */ /* Left-align the text */
    width: 100% !important; /* Make text take full width */
    margin: 0 !important; /* Add vertical margin */
    padding: 0 ; /* Add padding on the sides for spacing */
    padding-bottom: 10px;
    padding-top: 10px;
    box-sizing: border-box;
    hyphens: auto;
    word-wrap: break-word;
    display: inline-block; /* Include padding in the width calculation */
 /* Ensure long words break to the next line */
  }

  .intro h1 {
    font-size: 40px; /* Adjust the intro h1 specifically for mobile */
    align-self: start;
  }
  
  
  


  .overview2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px; /* Adjust as needed */
  }

  .overview2 .large2 {
    font-size: 16px; /* Adjust to match the desired size */
    font-weight: bold;
    line-height: 1.2;
    width: 100%;
    margin-bottom: 5px; /* Reduced margin between title and paragraph */
    box-sizing: border-box;
    text-align: left;
  }

  .overview2 div {
    font-size: 16px; /* Consistent font size */
    font-weight: normal;
    line-height: 1.5;
    width: 100%;
    margin: 0; /* Removes any top or bottom margin */
    padding: 0; /* Removes any padding */
    box-sizing: border-box;
    text-align: left;
  }

  

  .about-us_section-white {
    background-color: white;
  }

  .about-us_section {
    background-color: transparent;

  }

  .intro-text {
    font-size: 1.2em; /* Smaller font size for mobile */
    line-height: 1.2; /* Adjusted line-height for mobile */
    margin-bottom: 15px; /* Add some space below the h2 */
  }

  .collapsible-section {
    border-bottom: 1px solid black;
    padding: 15px 0;
  }

  .collapsible-header{
    display: flex;
    justify-content: space-between;
    align-items: left;
    font-size: 14px;
    font-weight: bold;
    color: black;
    cursor: pointer;
  }


  .collapsible-content{
    font-size: 14px;
    color: black;
    padding-top: 10px;
  }

  .collapsible-section:last-child {
    border-bottom: none;
  }

  .collapsible-header span {
    font-size: 20px;
    font-weight: bold;
    color: black;
  }
  .reputation {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
  }

  .reputation .subheader_text {
    font-size: 1em !important; /* Adjust font size for mobile */
    margin: 0 !important; /* Remove any margin */
    text-align: left; /* Align text to the left */
  }

  .img3 {
    width: 100%;
    height: auto;
    display: block;
    margin: 5 !important; /* Remove all margins to bring the image closer to text */
  }

  .overview_ul.mobile-layout {
    padding: 0 !important; /* Remove default padding */
    margin: 0 !important; /* Remove default margin */
    list-style-type: none; /* Removes default bullet points */
    padding-left: 20px; /* Add padding for list items */
  }

  .overview_ul.mobile-layout li {
    font-size: 14px !important; /* Reduced font size for mobile */
    line-height: 1.5 !important; /* Adjust line-height for readability */
    margin-bottom: 10px !important; /* Reduce space between list items */
    padding-bottom: 0 !important; /* Remove extra padding */
  }

  .overview_ul.mobile-layout li b {
    font-size: 16px !important; /* Slightly larger font size for emphasis */
    display: list-item; /* Ensures bullet style */
    list-style-type: disc; /* Adds bullet points */
    list-style-position: inside; /* Positions bullet inside */
    margin-bottom: 5px !important; /* Space between <b> and <p> */
  }

  .overview_ul.mobile-layout li p {
    margin: 0 0 0 15px !important; /* Align text with a slight indent */
    font-size: 14px !important; /* Match with the <li> font-size */
    line-height: 1.4 !important; /* Adjust line-height for readability */
  }
  .overview {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  .overview h1 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .overview .img1 {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
  }

  .overview p {
    font-size: 14px;
    line-height: 1.5;
    margin-top: 0;
  }
  .pledge {
    padding: 0 20px; /* Add padding to the sides to match the screenshot */
    text-align: left;
    margin-left: 20px;
    margin-right: 20px;  /* Align text to the left */


    
  }

  .pledge h1 {
    font-size: 1.3em !important; /* Adjust font size for visibility */
    line-height: 1.2 !important; /* Adjust line height for compactness */
    margin: 0 0 10px 0 !important; /* Remove top margin, add space below */
    font-weight: bold; /* Bold the text to match the screenshot */
  }

  .pledge h3 {
    font-size: 1em !important; /* Adjust font size for smaller subheading */
    line-height: 1.4 !important; /* Increase line height for readability */
    margin: 0 !important; /* Remove all margins for compactness */
    font-weight: normal; /* Regular weight for subheading */
    color: #666; 
    /* Match the lighter color from the screenshot */
  }
  
  
  

  






}



/* Wrapper for each card */
.core-value-card-wrapper {
  flex: 0 0 auto;
  width: 80vw; /* Adjust the width for each card */
  max-width: 300px; /* Max width for the cards */
  margin-right: 16px; /* Space between cards */
}

/* Mobile Scroll Container */
.core-values-mobile-scroll {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  padding: 20px;
  scrollbar-width: none; /* Hide scrollbar */
}

.core-values-mobile-scroll::-webkit-scrollbar {
  display: none; /* Hide scrollbar on mobile devices */
}

/* For Desktop View */
.core-values-desktop {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.core-value-card {
  background-color: #000000; /* Black background */
  color: #FFFFFF; /* White text */
  border-radius: 15px; /* Rounded corners */
  padding: 20px; /* Padding inside the card */
  width: 100%; /* Full width of the container */
  max-width: 300px; /* Max width for mobile view */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
}

.core-value-content {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: flex-start; /* Align items to the start */
}

.core-value-icon {
  width: 50px; /* Icon size */
  height: 50px; /* Icon size */
  margin-bottom: 10px; /* Space between icon and text */
}

.core-value-title {
  font-size: 20px; /* Title font size */
  font-weight: bold; /* Bold title */
  margin-bottom: 10px; /* Space below the title */
}

.core-value-description {
  font-size: 14px; /* Description font size */
  line-height: 1.6; /* Line height for better readability */
  color: #E0E0E0; /* Slightly lighter text color */
}


.pagination-dots {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.dot {
  height: 12px;
  width: 12px;
  margin: 0 5px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
  border: 2px solid black;
  transition: background-color 0.3s;
}

.dot.active {
  background-color: black;
}

.job-cards-container {
  display: flex;
  flex-direction: column; /* Stack the cards vertically */
  gap: 16px; /* Adjust the gap between the cards */
  align-items: center; /* Center align the cards horizontally */
  width: 100%; /* Ensure the container uses the full width */
  padding-top: 10px;
}
