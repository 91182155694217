.collapsible-box {
    width: 100%;
    background-color: #f4f4f4;
    border-radius: 10px;
    margin-bottom: 15px; /* Increased margin to make spacing between boxes more noticeable */
    padding: 20px; /* Increased padding to make the box bigger */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.collapsible-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    min-height: 50px; /* Ensures the box has a minimum height */
}

.collapsible-icon {
    width: 50px;
    height: 50px;
    margin-right: 15px; /* Slightly increased space between icon and title */
}

.collapsible-title {
    font-weight: bold;
    
    flex-grow: 1;
    text-align: left;
    line-height: 1.2;
     /* Ensures text is aligned to the left */
}

.collapsible-arrow {
    font-size: 20px;
    color: #aaa; /* Light grey color for the arrow */
}

.collapsible-content {
    margin-top: 15px; /* Increased margin to separate content from header */
    font-size: 14px;
    color: #333;
    text-align: left; /* Ensures content text is aligned to the left */
   
}
