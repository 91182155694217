.wrapper {
    font-size: 1vw;
    line-height: 1.6;
}

.blog-heading {
    font-size: 30px;
    font-weight: 700;
    color: black;
    font-family: Inter;
    justify-self: center;
}

.other-posts-section-heading {
    padding: 0 2vw;
}

.blog-feature-image-wrapper {
    display: flex;
    justify-items: center;
}

.blog-feature-image img {
    height: 60vh;
    border-radius: 14px;
    width: 100%;
    object-fit: cover;
}

.grow {
    transition: all .2s ease-in-out;
}

.grow:hover {
    transform: none;
}

.blog-date {
    font-size: 16px;
    letter-spacing: -0.06em;

}

.blog-section {
    margin: 25px 0;
    font-size: 1rem;
}

.blog-section-bold {
    font-size: 1rem;
}


.blog-section-image-wrapper {
    margin-top: 25px;
}

.blog-section-image img {
    height: 50vh;
    width: 60vw;
    object-fit: scale-down;
}

.blog-links {
    text-decoration: underline;
    color: #385cc5;
}

.other-posts-section {
    margin: 30px 20px;
}


.flex-column {
    flex-direction: column !important
}

.justify-self-center {
    justify-self: center !important
}

.justify-content-center {
    justify-content: center !important
}

.align-self-center {
    align-self: center !important
}


.p-5 {
    padding: 3rem !important
}


.px-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important
}

.px-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important
}

.px-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important
}

.py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important
}

.py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important
}


.pt-3 {
    padding-top: 1rem !important
}

.loading-text {
    font-family: inherit;
    font-size: medium;
}

.page-margin {

    padding-right: 6rem !important;
    padding-left: 6rem !important
}


.blog-content-promo-wrapper {
    height: 100vh !important;
    background-color: black !important;
}

.justify-content-center {
    justify-content: center !important
}

.align-self-center {
    align-self: center !important
}

.blog-content-promo {
    height: max-content;
    margin: 0 !important;
    color: white;
    align-items: center !important;

}

.blog-content-promo-body {
    width: 45vw;
}

.blog-content-promo-header {
    font-size: 1.75rem;
    font-weight: 750;
    padding: 2vw 0;
}

.blog-content-promo-img img {
    object-fit: scale-down;
    width: 45vw;

}

.blog-content-promo-subheadding {
    font-size: 1.2rem;
    font-weight: 500;
}

.btn_link {
    margin: 20px 0px;
    text-decoration: none;

}

.promo-button {
    width: max-content;
}

.green-button-product {
    background-color: var(--primary);
    border: none;
    border-radius: 5px;
    color: black;
    font-weight: bold;
    padding: 10px 35px;
    transition: all 0.3s;
    text-align: center;
}


.green-button-product a {
    text-decoration: none !important;
}


@media (min-width: 1441px) {
    .page-margin {
        padding: 0 6vw !important;
    }

    .blog-heading {
        font-size: 3rem;
        font-weight: 800;
    }

    .blog-feature-image img {
        height: 50vh;
        object-fit: cover;
    }

    .blog-section {
        margin: 25px 0;
        font-size: 2.2rem;
    }

    .blog-date {
        font-size: 2.2rem;
    }

    .blog-section-heading {
        font-size: 2.5rem;
    }


    .blog-section-image-wrapper {
        margin-top: 25px;
    }

    .blog-section-image img {

        height: 40vh;
        width: 60vw;
        object-fit: contain;
    }

    .other-posts-section {
        margin: 5rem 0;
    }

    .blog-content-promo-body {
        width: 45vw;
    }


    .blog-content-promo-header {
        font-size: 3.2rem;
        font-weight: 750;
        padding: 2vw 0;
    }

    .blog-content-promo-img img {
        object-fit: contain;
        width: 45vw;

    }

    .blog-content-promo-subheadding {
        font-size: 1.9rem;
        font-weight: 500;
    }

    .blog-content-promo-wrapper {
        height: 70vh !important;

    }

    .other-posts-section-heading {
        padding: 0 3vw;
    }


}

@media (max-width: 768px) {
    .page-margin {
        padding-right: 15px !important;
        padding-left: 15px !important
    }

    .blog-heading {
        font-size: 24px;
        letter-spacing: -0.05em;
        font-weight: 750;
    }

    .blog-date {
        font-size: 18px;
        letter-spacing: -0.06em;
    }

    .blog-feature-image-wrapper {
        width: 100%;
        padding-right: 15px !important;
        padding-left: 15px !important;
        border-radius: 14px;
    }

    .blog-section-image img {
        border-radius: 10px;
        width: 100%;
        height: 100%;
        object-fit: scale-down;
    }

    .blog-feature-image img {
        height: auto;
        width: 100%;
        object-fit: scale-down;
        border-radius: 10px;
    }

    .blog-section {
        font-size: 16px;
        margin: 15px 0;
    }

    .grow {
        transition: all .2s ease-in-out;
    }

    .grow:hover {
        transform: scale(1.03);
    }

    .other-posts-section {
        margin: 0;
        padding-right: 15px !important;
        padding-left: 15px !important
    }

    .other-posts-section-heading {
        padding: 0;
    }

    .other-posts-wrapper {
        align-items: center;
    }
}