/* Styling for the container of each post preview */

.postpreview-card-wrapper {
    background-color: var(--background-grey);
    margin: 20px;
    width: 100%;
    border-radius: 10px;
    justify-content: center;
    font-size: 1.7rem;
    height: 28.5rem;
}

.postpreview-card-wrapper:hover {
    background-color: #dbdbdb;
    transition: 0.3s;
}

.postpreview-card {
    display: flex;
    flex-direction: row;
}

img, svg {
    vertical-align: middle;
}

*, ::after, ::before {
    box-sizing: border-box;
}


.flex {
    display: flex;
}

.blog-preview-heading {
    font-size: 1.5rem;
    font-weight: 650;
    letter-spacing:0.001rem;
}

.blog-preview-content-gray {
    color: #616265;
    font-weight: 700;
    font-size: small;
    height: 30px;
}

.h-100 {
    height: 100% !important;
}

.blog-preview-content {
    font-size: 1.1rem;
    font-weight: 400;
    flex: 1;
}

.postpreview-card-wrapper a {
    text-decoration: none !important;
    color: black !important;
}

.blog-preview-image-wrapper {
    display: inline-block;
    overflow: hidden;
    border-radius: 14px;
    vertical-align: middle;
    justify-content: flex-end;
    align-self: center;
    margin: 0 auto; 
}

.blog-preview-image {
    width: 100% !important;
    height: 26.5rem;
    border-radius: 10px;
    object-fit: cover;
    align-self: center;
}

.grow {
    transition: all .2s ease-in-out;
}

.grow:hover {
    transform: scale(1.03);


}

.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x))
}

.row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y)
}


.col-5 {
    flex: 0 0 auto;
    width: 46%;
}

.col-6 {
    flex: 0 0 auto;
    width: 50%;
}


.h-100 {
    height: 100% !important;
}

.flex-column {
    flex-direction: column !important;
}


.justify-content-center {
    justify-content: center !important;
}

.justify-content-around {
    justify-content: space-around !important;
}

.justify-content-evenly {
    justify-content: space-evenly !important;
}

.justify-content-between {
    justify-content: space-between !important;
}


.p-4 {
    padding: calc(20px + 1vw) !important
}


.text-decoration-none {
    text-decoration: none !important
}


@media (min-width: 1441px) {
    .postpreview-card-wrapper {
        margin: 30px 20px;
        width: 98%;
        border-radius: 10px;
        justify-content: center;
        font-size: small;
        height: 48.5rem;
    }

    .blog-preview-content, .read-more {
        font-size: 1.3rem;
    }

    .blog-preview-content-gray {
        font-size: 20px;
        height: 50px;
    }

    .blog-preview-content {
        font-size: 24px;
    }

    .blog-preview-image {
        width: 40vw !important;
        height: 46rem;
    }

    .blog-preview-heading {
        font-size: 2.1rem;
    }

    .blog-preview-content-gray {
        font-size: 1.4rem;
    }

    .blog-preview-content {
        font-size: 1.9rem;
    }
}

@media (max-width: 768px) {
    .postpreview-card-wrapper {
        /*background-color: var(--background-grey);*/
        margin: 10px 0;
        width: 100%;
        height: auto;
    }
       .blog-preview-image {
        width: 100% !important;
        height: 100% !important;
           object-fit: fill;
    }

    .blog-preview-heading {
        font-size: 1rem;
         letter-spacing: -0.05em;
    }

    .blog-preview-content-gray {
        font-size: 0.7rem;
    }

    .blog-preview-content {
        font-size: 0.9rem;
         letter-spacing: -0.05em;
    }

}