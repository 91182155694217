.success-page {
  padding: 5vw 14vw;
  text-align: left;
}

.success-page h1 {
  font-size: 2em;
  margin-bottom: 1em;
}

.success-page p {
  font-size: 1.2em;
  line-height: 1.6;
  margin-bottom: 1em;
}

.success-page a {
  color: #00f2ea;
  text-decoration: none;
}

.success-page a:hover {
  text-decoration: underline;
}
